import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"

import { instance } from "../../../utils/functions"
import { PaginationComponent, Progress } from "../../../utils/components"
import { EmptyCard } from "../../../utils/components/misc"
import UserListCard from "./card"

const API = `user/user_admin/get_rows`
class UsersList extends Component {
  state = {
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 5,
    filter: [],
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { page, limit, filter } = this.state
    let start = page * limit
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter: { ...filter } },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  applySearch = (name) => (e) => {
    let entry = { name, value: e.target.value, condn: "like" }
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    if (index) filter[index] = entry
    else filter.push(entry)
    this.setState({ filter, page: 0 }, this.fetchRows)
  }

  applyFilter = (name, value) => {
    let entry = { name, value }
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    if (index) filter[index] = entry
    else filter.push(entry)
    if (value == "" || value == null) delete filter[index]
    this.setState({ filter, page: 0 }, this.fetchRows)
  }

  getFilterVal = (name) => {
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    console.log("index ? filter[index] : null", index ? filter[index] : null)
    return index !== -1 ? filter[index].value : null
  }

  renderPagination = () => (
    <React.Fragment>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item md={6}>
          <Typography>{`Page ${this.state.page + 1} ( ${
            this.state.total
          } total )`}</Typography>
        </Grid>
        <Grid item md={6}>
          {this.state.isLoading && (
            <PaginationComponent
              {...this.state}
              onPageChange={({ selected }) => {
                this.setState({ page: selected }, this.fetchRows)
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )

  renderTop = () => {
    let { service_status } = this.props
    let { filter } = this.state
    return (
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item sm={4}>
          <TextField
            onChange={this.applySearch(
              "user.first_name,user.last_name,user.phone"
            )}
            placeholder="Search by name , phone ,email"
            fullWidth
          />
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
    )
  }

  render() {
    let { isLoading, total, rows, page } = this.state
    let { classes } = this.props
    let cont = null
    if (!rows.length) cont = isLoading ? <Progress /> : <EmptyCard card />
    else {
      cont = rows.map((x) => (
        <Card key={x.id} className={classes.card}>
          <UserListCard row={x} />
        </Card>
      ))
    }
    return (
      <div>
        {this.renderTop()}
        {cont}
        {this.renderPagination()}
      </div>
    )
  }
}

const styles = (theme) => ({
  card: {
    marginBottom: 10,
  },
})

const mapStateToProps = ({ enums }, ownProps) => {
  const service_status = enums.rows.service_status.vt
  return { service_status }
}
export default connect(mapStateToProps)(withStyles(styles)(UsersList))
