import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

import ReportRow from "../row"
import ReportRowForm from "../form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"
import { FormResult, FormButton } from "../../../../utils/form"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
const API_SUBMIT = `report/update`

class ReportSolutionYear extends Component {
  state = {
    isLoading: true,
    row: this.props.row || {},
    result: null,
    report: {},
    onEdit: !this.props.solutionAdded,
    tabVal: null,
    showAgeForm: !this.props.solutionAdded,
    submitIsLoading: false,
  }

  componentWillMount = () => {
    if (this.props.row) return
    var d = new Date()
    let row = { year_year: d.getFullYear(), year_month_: d.getMonth() + 1 }
    this.setState({ row })
  }

  renderRow = (name, title, val) =>
    this.state.onEdit ? (
      <ReportRowForm
        key={name}
        title={title}
        text={val}
        onChange={(e) => {
          let { row } = this.state
          row[name] = e.target.value
          this.setState({ row })
        }}
      />
    ) : (
      <ReportRow key={name} title={title} text={val} />
    )

  handleSubmit = () => {
    let { row } = this.state
    let { requirement_id, fetchRow } = this.props
    row.requirement_id = requirement_id
    this.setState({ submitIsLoading: true, result: null })
    instance({
      url: API_SUBMIT,
      method: "post",
      data: row,
    }).then((result) => {
      let { res, msg } = result
      if (res == 2) {
        this.setState({ result })
        return
      } else fetchRow()
      this.setState({ submitIsLoading: false })
    })
  }

  renderActions = () => {
    let { result, submitIsLoading, onEdit } = this.state
    return (
      <React.Fragment>
        <FormResult result={result} />
        {onEdit && (
          <FormButton
            color="primary"
            fullWidth
            variant="contained"
            loading={submitIsLoading}
            onClick={this.handleSubmit}
          />
        )}
        {!onEdit && (
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => this.setState({ onEdit: true })}
          >
            <Icon>edit</Icon> Edit
          </Button>
        )}
      </React.Fragment>
    )
  }

  renderRows = () => {
    let { onEdit, row } = this.state
    let { year_month_, year_year } = row
    let rows = []
    for (var i = 1; i <= 12; i++) {
      if (year_month_ >= 12) {
        year_month_ = 0
        year_year++
      }
      rows.push(
        this.renderRow(
          year_month_,
          `${monthNames[year_month_]} - ${year_year}`,
          row[year_month_] || null
        )
      )
      year_month_++
    }
    rows.push(
      this.renderRow(
        "year_conclusion",
        "Conclusion",
        row.year_conclusion || null
      )
    )
    return <React.Fragment>{rows}</React.Fragment>
  }

  render() {
    return (
      <div>
        {this.renderRows()}
        {this.renderActions()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportSolutionYear))
