import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"
import { fetchReqRowDB } from "../actions"
const API = `requirement/complete`

class InteractionCompleteButton extends Component {
  state = {
    isLoading: false,
    result: null,
  }

  handleSubmit = () => {
    let { requirement_id, dispatch } = this.props
    this.setState({ isLoading: true, result: null })
    instance({
      method: "post",
      url: API,
      data: { requirement_id },
    }).then((result) => {
      this.setState({ isLoading: false })
      this.setState({ result })
      dispatch(fetchReqRowDB(requirement_id))
      if (result.res != 1)
        dispatch(pushMsg(`Thank you! Interaction completed.`, "success"))
    })
  }

  render() {
    let { isLoading, result } = this.state
    return (
      <div style={{ padding: 10 }}>
        <Typography component="span">
          If you have received enough response, Please complete this Interaction
        </Typography>
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          fullWidth
          label="Complete Interaction"
          onClick={this.handleSubmit}
          variant="outlined"
          style={{ color: "green" }}
        />
        <Typography
          component="span"
          style={{ fontSize: 10, textAlign: "center" }}
        >
          Interaction will be automatically closed if there is no response for
          next 24 hours.
        </Typography>
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(InteractionCompleteButton)
)
