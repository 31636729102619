import React from "react"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import DefaultComponent from "./default"
import Dashboard from "./dashboard"

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Dashboard {...rest}>
        <Component {...props} />
      </Dashboard>
    )}
  />
)

export default Layout
