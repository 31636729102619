import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import Divider from "@material-ui/core/Divider"

import { FormResult, FormButton } from "../../../../utils/form"
import { guid } from "../../../../utils/functions"
import { instance } from "../../../../utils"
import { Progress } from "../../../../utils/components"
import { pushMsg } from "../../../../navigation/actions"
const API_FETCH_ROWS = `astrologer/astrologer_phone_timings/get_rows`
const API_UPDATE = `astrologer/astrologer_phone_timings/update`

class PhoneTimings extends Component {
  state = {
    fetchIsLoading: true,
    isLoading: false,
    rows: [],
    result: null,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    this.setState({ fetchIsLoading: true })
    let user_id = this.props.row.user_id
    instance({
      method: "post",
      url: API_FETCH_ROWS,
      data: { filter: { user_id } },
    }).then(({ rows }) => {
      this.setState({ fetchIsLoading: false, rows })
    })
  }

  handleChange = (timing_id, name) => (event) => {
    let { rows } = this.state
    rows.map((x, k) => {
      if (x.timing_id == timing_id) rows[k][name] = event.target.value
    })
    this.setState({ rows })
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { dispatch } = this.props
    let { rows } = this.state
    let user_id = this.props.row.user_id
    let newRows = {}
    rows = rows.map((x) => (newRows[x.timing_id] = x))
    instance({
      method: "post",
      url: API_UPDATE,
      data: { user_id, rows: newRows },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res !== 1) this.setState({ result })
      else dispatch(pushMsg(result.msg, "success"))
    })
  }

  pushRow = (day) => {
    let { rows } = this.state
    rows.push({
      timing_id: guid(),
      day,
      time_from: null,
      time_to: null,
    })
    this.setState({ rows })
  }

  cloneRow = (day) => {
    let { rows } = this.state
    let rowsClone = rows.filter((x) => x.day === day)
    let { week_days } = this.props
    let newRows = []
    Object.keys(week_days).map((x) => {
      rowsClone.map((x2) => {
        x2.timing_id = guid()
        x2.day = x
        newRows.push({ ...x2 })
      })
    })
    this.setState({ rows: newRows })
  }

  removeRow = (timing_id) => () => {
    let { rows } = this.state
    rows = rows.filter((x) => x.timing_id !== timing_id)
    this.setState({ rows })
  }

  renderTimePicker = (timing_id, name, val) => (
    <TextField
      fullWidth
      id={timing_id}
      type="time"
      defaultValue={val}
      onChange={this.handleChange(timing_id, name)}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  )

  renderRowForm = (row) => (
    <React.Fragment key={row.timing_id}>
      <Grid item xs={5}>
        {this.renderTimePicker(row.timing_id, "time_from", row.time_from)}
      </Grid>
      <Grid item xs={5}>
        {this.renderTimePicker(row.timing_id, "time_to", row.time_to)}
      </Grid>
      <Grid item xs={2}>
        <Button
          component="span"
          size="small"
          onClick={this.removeRow(row.timing_id)}
        >
          <Icon size="large">delete</Icon>
        </Button>
      </Grid>
    </React.Fragment>
  )

  renderRowActions = (day, rowsCount) => (
    <React.Fragment>
      <Grid item xs={6}>
        <Button onClick={() => this.pushRow(day)} component="span" size="small">
          <Icon size="small">add</Icon> Add slot
        </Button>
      </Grid>
      <Grid item xs={6}>
        {rowsCount > 0 && (
          <Button
            onClick={() => this.cloneRow(day)}
            component="span"
            size="small"
            color="primary"
          >
            <Icon size="small">file_copy</Icon> copy this timing to all days
          </Button>
        )}
      </Grid>
    </React.Fragment>
  )

  renderRow = (day) => {
    let { week_days } = this.props
    let { rows } = this.state
    let rowsX = rows.filter((x) => x.day === day)
    return (
      <React.Fragment key={day}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="subheading">
            {week_days[day]}
          </Typography>
        </Grid>
        {!rowsX.length && (
          <Grid item xs={12}>
            <Typography color="error" variant="caption" align="center">
              No slots added!
            </Typography>
          </Grid>
        )}
        {rowsX.map((x) => this.renderRowForm(x))}
        {this.renderRowActions(day, rowsX.length)}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    let { week_days } = this.props
    let { fetchIsLoading, isLoading, result } = this.state
    if (fetchIsLoading) return <Progress loading={1} />
    return (
      <Grid container spacing={16}>
        {Object.keys(week_days).map((x) => this.renderRow(x))}
        <Grid item xs={12}>
          <FormResult result={result} />
          <FormButton
            loading={isLoading}
            label="Save"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ maxWidth: 600, margin: 0 }}
          />
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let week_days = enums.rows.week_days.vt
  return { week_days }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneTimings))
