import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import MoreIcon from "@material-ui/icons/MoreVert"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Typography from "@material-ui/core/Typography"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"

import { logOut } from "../../../../modules/auth/actions"

const drawerWidth = "auto"

const styles = (theme) => ({
  drawerHeader: {
    height: 60,
    display: "flex",
    background: "#7b1fa2",
    color: "#fff",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuTex: {
    color: "#414141",
    fontSize: 14,
  },
  contactIfo: {
    padding: "20px 30px",
    background: "#ece4f1",
  },
})

const menuItemsDefault = [
  {
    text: "Astrologers",
    icon: "people",
    href: "/astrologers",
  },
  {
    text: "Users",
    icon: "favorite",
    href: "/users",
  },
  {
    divider: true,
  },
]

const menuItemsUser = [
  {
    text: "Report Bookings",
    icon: "question_answer",
    href: "/report-booking-list",
  },
  {
    text: "Phone calls",
    icon: "phone",
    href: "/phonecall-list",
  },
  {
    text: "Live Chat",
    icon: "chat",
    href: "/live-chat",
  },
  {
    text: "Analytics",
    icon: "assessment",
    href: "/analytics",
  },
  {
    divider: true,
  },
]

const menuLogout = [
  {
    text: "Log out",
    icon: "power_settings_new",
    href: "/logout",
  },
]

class LeftMenu extends React.Component {
  state = {
    open: false,
    menuItems: menuItemsDefault,
  }

  componentWillReceiveProps = () => {
    let { user } = this.props
    if (user == null) return
    let menuItems = [...menuItemsDefault]
    menuItems.splice(3, 0, ...menuItemsUser)
    menuItems.splice(12, 0, ...menuLogout)
    this.setState({ menuItems })
  }

  toggleDrawer = (open) => () => {
    this.setState({ open })
  }

  handleLougout = () => {
    this.props.dispatch(logOut())
  }

  renderHead = () => {
    let { classes, open, user } = this.props
    return (
      <div className={classes.drawerHeader}>
        <Icon>person</Icon>
        <Typography
          style={{ color: "#fff", width: "55%", fontSize: 16, marginLeft: -15 }}
          variant="subtitle1"
        >
          {user ? user.first_name : "Login & Sign Up"}
        </Typography>
        <img
          style={{
            width: 33,
            background: "#fff",
            padding: 5,
            borderRadius: "100%",
          }}
          src="/img/favicon.png"
        />
      </div>
    )
  }

  render() {
    const { classes, history, user } = this.props
    if (user == null) return null
    let { menuItems } = this.state
    const sideList = (
      <List>
        {menuItems.map((item, index) =>
          item.divider ? (
            <Divider style={{ margin: "6px 0" }} key={index} />
          ) : (
            <ListItem
              button
              key={index}
              onClick={() => {
                if (item.href) history.push(item.href)
              }}
              selected={item.href == this.props.pathname}
            >
              <ListItemIcon>
                <Icon style={{ fontSize: 16 }}>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          )
        )}
      </List>
    )

    return sideList

    return (
      <React.Fragment>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
          onClick={this.toggleDrawer(true)}
        >
          <Icon>view_headline</Icon>
        </IconButton>

        <Drawer
          open={this.state.open}
          onClose={this.toggleDrawer(false)}
          //onOpen={this.toggleDrawer( true)}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant={window.innerWidth > 1200 ? `permanent` : `persistent`}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {this.renderHead()}
            <Divider />
            {sideList}
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth, enums, router }) => {
  let { user } = auth
  const pathname = router.location.pathname
  return { user, pathname }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(LeftMenu))
)
