import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

import { getUploadImge, timeAgo } from "../../../utils/functions"
import { instance } from "../../../utils"
import UserFetcher from "../../users"
const MARK_AS_READ_API = `chat/mark_as_read`
class ChatRow extends Component {
  componentWillMount = () => {
    let { isUser, row } = this.props
    if (!isUser && row.chat_read_time == null) this.markAsread()
  }

  markAsread = () => {
    let { row } = this.props
    instance({
      method: "post",
      url: MARK_AS_READ_API,
      data: { chat_id: row.chat_id },
    })
  }

  renderAvatar = () => (
    <React.Fragment>
      {this.props.user && (
        <ListItemAvatar>
          <Avatar src={getUploadImge(this.props.user.image, "user/avatar")} />
        </ListItemAvatar>
      )}
    </React.Fragment>
  )

  renderContent = () => {
    let { user, row, isUser, classes } = this.props
    return (
      <React.Fragment>
        {`" — ${row.chat_message}"`}
        <Typography
          component="span"
          className={classes.inline}
          color="textPrimary"
        >
          {timeAgo(row.chat_join_date)}
        </Typography>
        {isUser && row.chat_read_time && (
          <Typography component="span" color="primary">
            <Icon style={{ fontSize: 14 }}>done_all</Icon>{" "}
            {timeAgo(row.chat_read_time)}
          </Typography>
        )}
      </React.Fragment>
    )
  }

  render() {
    let { user, row, isUser, classes } = this.props
    let user_name = isUser
      ? `You`
      : user
      ? `${user.first_name} ${user.last_name || ""}`
      : ""
    return (
      <UserFetcher id={this.props.user_id}>
        <List className={classes.root}>
          <ListItem
            alignItems="flex-start"
            className={classes[isUser ? "ownChat" : "receiverChat"]}
          >
            {!isUser && this.renderAvatar()}
            <ListItemText
              secondary={this.renderContent()}
              primary={user_name}
            />
            {isUser && this.renderAvatar()}
          </ListItem>
        </List>
      </UserFetcher>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  ownChat: {
    //textAlign : 'right'
  },
})

const mapStateToProps = ({ auth, users }, ownProps) => {
  let { user_id } = ownProps.row
  let isUser = user_id == auth.user.id
  let user = users.rows[user_id]
  return { isUser, user, user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(ChatRow))
