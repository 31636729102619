import React, { useState } from "react"
import { Tabs, Tab } from "@material-ui/core"
import SearchRoot from "./SearchRoot"
import Logs from "./Logs"

function ChatTabs() {
  const [value, setValue] = useState(0)

  function handleChange(_e, value) {
    setValue(value)
  }

  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Logs" />
        <Tab label="Search" />
      </Tabs>
      {value === 0 && <Logs />}
      {value === 1 && <SearchRoot />}
    </div>
  )
}

export default ChatTabs
