import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import WalletForm from "./form"
import ModalComponent from "../../../../../utils/components/modal"
import { _toFixed } from "../../../../../utils/functions"

class WalletLeftCard extends Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  renderContent = () => {
    const { classes, theme, auth, constants, trn_amount } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={6}>
          Available balance
        </Grid>
        <Grid item xs={6} className={classes.amount}>
          {_toFixed(this.props.row.wallet)} {constants.CURRENCY}
        </Grid>
      </Grid>
    )
  }

  addButton = () => (
    <Button
      style={{ marginTop: 20 }}
      color="secondary"
      variant="outlined"
      size="small"
      onClick={() => this.setState({ modalOpen: true })}
    >
      <Icon style={{ marginRight: 10, color: "green" }}>swap_horiz</Icon> Adjust
    </Button>
  )

  render() {
    const { classes, theme, auth, constants, row } = this.props
    let { modalOpen } = this.state
    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={4} className={classes.leftGrid}>
            <Icon className={classes.icon}>account_balance_wallet</Icon>
          </Grid>
          <Grid item xs={8} className={classes.rightGrid}>
            <div className={classes.content}>
              {this.renderContent()}
              {!modalOpen && this.addButton()}
            </div>
          </Grid>
        </Grid>
        {modalOpen && (
          <WalletForm user_id={row.user_id} onSuccess={this.props.fetchRows} />
        )}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  icon: {
    fontSize: 100,
    margin: "0 auto",
  },
  balance: {
    fontSize: 32,
    color: "blue",
  },
  amount: {
    textAlign: "right",
    paddingRight: 20,
    color: "blue",
  },
})

const mapStateToProps = ({ auth, enums }, { trn_amount }) => {
  let constants = enums.rows.constants
  return { auth, constants }
}
export default connect(mapStateToProps)(withStyles(styles)(WalletLeftCard))
