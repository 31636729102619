import { history } from "./root"
import { push } from "connected-react-router"

const params = function (data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&")
}

export const routerPush1 =
  (pathname, search = {}, replace = false) =>
  (dispatch) => {
    dispatch(push(pathname))
  }

export function routerPush(pathname, search = {}, replace = false) {
  search = params(search)
  if (replace) history.replace({ pathname, search })
  else history.push({ pathname, search })
}

export const pushMsg =
  (msg, type = null) =>
  (dispatch) => {
    dispatch({
      type: "PUSH_MSG",
      payload: { msg, type },
    })
  }

export function removeMsg(id) {
  return (dispatch) => {
    return dispatch({
      type: "REMOVE_MSG",
      payload: id,
    })
  }
}
