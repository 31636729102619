import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Icon from "@material-ui/core/Icon"

import CustomForm from "./form"
import CustomRow from "./row"
import { instance } from "../../../../utils"
import { Progress, ModalComponent } from "../../../../utils/components"
import { EmptyCard } from "../../../../utils/components/misc"

const API = `astrologer/astrologer_custom_service/get_rows`

class CustomService extends Component {
  state = {
    isLoading: true,
    rows: [],
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    this.setState({ isLoading: true })
    let { user_id } = this.props
    instance({
      method: "post",
      url: API,
      data: { filter: { user_id } },
    }).then(({ rows }) => {
      this.setState({ isLoading: false, rows })
    })
  }

  renderAddButton = () => {
    return (
      <React.Fragment>
        <Button
          style={{ margin: "0 0 30px 0" }}
          color="secondary"
          variant="outlined"
          size="small"
          fullWidth
          onClick={() => this.setState({ modalOpen: true })}
        >
          <Icon style={{ marginRight: 10, color: "green" }}>add</Icon> Add
          service
        </Button>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Add new custom service"
          content={() => (
            <CustomForm
              _setState={this._setState}
              refreshData={this.fetchRows}
              row={this.props.row}
            />
          )}
        />
      </React.Fragment>
    )
  }

  render() {
    let { isLoading, rows } = this.state
    if (isLoading) return <Progress />
    return (
      <div>
        {!rows.length && <EmptyCard msg="No services added yet!" />}
        {this.renderAddButton()}
        {rows.map((x, k) => (
          <React.Fragment key={x.acs_id}>
            <CustomRow row={x} refreshData={this.fetchRows} />
            {rows.length !== k + 1 && (
              <Divider style={{ margin: "20px 0" }} light />
            )}
          </React.Fragment>
        ))}
      </div>
    )
    /*return(
			<div>
			{Object.keys( report_services ).map( x=>(
				<React.Fragment key={x} >
					<Typography color="primary" variant="subtitle1" gutterBottom >{report_services[x]}</Typography>
					<ReportRow row={Object.values(rows).find(xr=>xr.mr_service_id === x)} />
					<Divider style={{margin:'20px 0'}} light/>
				</React.Fragment>
			))}
			</div>
		)*/
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums, auth }, { row }) => {
  let user_id = row.id
  return { user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(CustomService))
