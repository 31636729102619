import React, { useState, useEffect } from "react"
import { Typography } from "@material-ui/core"
import { apiv2Instance } from "../../../utils"

export default function TotalChats() {
  const [count, setCount] = useState(null)

  useEffect(() => {
    ;(async function () {
      const { data } = await apiv2Instance.get("/admin/chat/count")
      setCount(data.count)
    })()
  }, [])

  return (
    <div style={{ marginTop: "15px", marginBottom: "10px" }}>
      <Typography>Total chats: {count ? count : "--"}</Typography>
    </div>
  )
}
