import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { Progress } from "../../utils/components"
import { fetchRow } from "./actions"

const MyContext = React.createContext()

class UserGroupFetcher extends Component {
  componentWillMount = () => {
    let { ids, users, dispatch } = this.props
    ids.map((id) => {
      if (!users.idsLoading.includes(id) && !users.rows[id])
        dispatch(fetchRow(id))
    })
  }

  render() {
    let { isLoading, user, visualise } = this.props
    if (isLoading) return visualise ? <Progress /> : null
    const { children, ...otherProps } = this.props
    const childrens = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        index,
        user,
      })
    })
    return <React.Fragment>{childrens}</React.Fragment>
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ users }, { ids }) => {
  let isLoading = false
  ids.map((id) => {
    if (users.idsLoading.includes(id) || !users.rows[id]) isLoading = true
  })
  return { users, isLoading }
}
export default connect(mapStateToProps)(withStyles(styles)(UserGroupFetcher))
