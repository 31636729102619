import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"

class FormRadioGroup extends Component {
  render() {
    let { source, value, onChange } = this.props
    return (
      <RadioGroup
        row={window.innerWidth > 600}
        name="birth_gender"
        value={value}
        onChange={onChange}
      >
        {source.map((x) => (
          <FormControlLabel
            key={x.val}
            value={x.val}
            control={<Radio />}
            label={x.text}
          />
        ))}
      </RadioGroup>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default withStyles(styles)(FormRadioGroup)
