import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import LinearProgress from "@material-ui/core/LinearProgress"
import CssBaseline from "@material-ui/core/CssBaseline"

import Header from "./components/header/index"
import Msg from "./msg"

import { fetchAuth } from "../../modules/auth/actions"
import { clearLoadingIds } from "../../modules/users/actions"
import { fetchRows as fetchEnums } from "../../store/enums/actions"
import { createUrl } from "../../utils/functions"

const styles = (theme) => ({
  root: {},

  heroContent: {
    maxWidth: 1370,
    paddingTop: 80,
    margin: "0 auto",
  },
  layout: {
    width: "auto",
    maxWdith: 1000,
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    margin: "0 auto",
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1170,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
})

class DefaultComponent extends Component {
  componentWillMount = () => {
    let { isProtected, dispatch } = this.props
    dispatch(clearLoadingIds())
    dispatch(fetchAuth())
    dispatch(fetchEnums())
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      !nextProps.isLoading &&
      nextProps.isProtected &&
      !nextProps.isLoggedIn
    ) {
      let { pathname, search } = nextProps.location
      let redirect = `${pathname}${search}`
      this.props.history.push(createUrl("/authentication", { redirect }))
    }
  }

  renderContent = () => {
    let { isLoggedIn, isProtected } = this.props
    if (!isLoggedIn && isProtected) return null
    return (
      <main className={this.props.classes.layout}>
        <div className={this.props.classes.heroContent}>
          {this.props.children}
        </div>
      </main>
    )
  }

  render() {
    const { classes, isLoading } = this.props
    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        <Msg />
        <Header />
        {isLoading && <LinearProgress color="secondary" />}
        {!isLoading && this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = ({ enums, astrologers, auth, router }) => {
  let enumsIsLoading = enums.isLoading
  let authIsLoading = auth.isLoading
  let isLoading = authIsLoading || !enums.ids.length
  let isLoggedIn = auth.isLoggedIn
  return { isLoading, auth, isLoggedIn }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(DefaultComponent))
)
