import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"

class FormCheckBox extends Component {
  render() {
    let { source, value, onChange } = this.props
    return (
      <FormGroup row={window.innerWidth > 600}>
        {source.map((x) => (
          <FormControlLabel
            checked={value.includes(x.val)}
            key={x.val}
            value={x.val}
            label={x.text}
            control={<Checkbox />}
            onChange={(event, checked) => {
              if (checked) value.push(event.target.value)
              else value = value.filter((x) => x !== event.target.value)
              onChange(value)
            }}
          />
        ))}
      </FormGroup>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default withStyles(styles)(FormCheckBox)
