import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"

import ModalComponent from "../../../utils/components/modal"
import AstrologerListCard from "../list/card"
import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"

const API = `notification/direct_sms`

class AstrologerActionsSms extends Component {
  state = {
    modalOpen: false,
    isLoading: false,
    result: null,
    message: "",
  }

  _setState = (newState) => this.setState(newState)

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { message } = this.state
    let { dispatch } = this.props
    let user_id = this.props.row.user_id
    instance.post(API, { message, user_id }).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
      this.setState({ modalOpen: false })
    })
  }

  renderContent = () => {
    let { isLoading, result, message } = this.state
    return (
      <React.Fragment>
        <AstrologerListCard row={this.props.row} />
        <TextField
          fullWidth
          multiline
          variant="filled"
          name="message"
          value={message}
          label="Enter Message"
          onChange={(e) => this.setState({ message: e.target.value })}
        />

        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          fullWidth
          variant="outlined"
          color="primary"
          label="Send Now"
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <IconButton
          color="default"
          variant="outlined"
          size="small"
          onClick={() => this.setState({ modalOpen: true })}
        >
          <Icon>sms</Icon>
        </IconButton>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Send SMS"
          content={() => this.renderContent()}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(AstrologerActionsSms)
)
