import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"

import { EmptyCard, UserSearch } from "../../../utils/components/misc"
import ReportBookingListRow from "./row"
import { instance } from "../../../utils/functions"
import { PaginationComponent, Progress } from "../../../utils/components"

const API = `requirement/get_rows`

class ReportBookingList extends Component {
  state = {
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 5,
    filter: [],
    tabVal: "active",
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { page, limit } = this.state
    let start = page * limit
    let { user } = this.props
    let order_by = "requirement_join_date-DESC"
    let filter = {}
    let requirement_status = this.getReqStatusArray()
    if (requirement_status)
      filter.requirement_status = {
        name: "requirement_status",
        value: requirement_status.join(","),
      }
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter, order_by },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  applySearch = (name, value) => {
    let entry = { name, value, condn: "like" }
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    if (index) filter[index] = entry
    else filter.push(entry)

    if ((!value || value == "" || value == null) && index)
      console.log(index, filter)
    this.setState({ filter, page: 0 }, this.fetchRows)
  }

  applyFilter = (name, value) => {
    let entry = { name, value }
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    if (index !== -1) filter[index] = entry
    else filter.push(entry)

    if (!value || value == "" || value == null) delete filter.splice(index, 1)
    this.setState({ filter, page: 0 }, this.fetchRows)
  }

  renderPagination = () => (
    <React.Fragment>
      <Typography>{`Page ${this.state.page + 1} ( ${
        this.state.total
      } total )`}</Typography>
      <TextField
        defaultValue={this.state.limit}
        onChange={(e) => {
          this.setState(
            { limit: parseInt(e.target.value) || 10 },
            this.fetchRows
          )
        }}
      />
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    </React.Fragment>
  )

  getReqStatusArray = () => {
    let requirement_status_arr = false
    let { tabVal } = this.state
    switch (tabVal) {
      case "active":
        requirement_status_arr = [2, 3, 4, 5, 6, 7, 8]
        break
      case "completed":
        requirement_status_arr = [9]
        break
      default:
        requirement_status_arr = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        break
    }
    return requirement_status_arr
  }

  handleChange = (event, value) =>
    this.setState({ tabVal: value, page: 0 }, this.fetchRows)

  renderTabs = () => (
    <Tabs
      value={this.state.tabVal}
      onChange={this.handleChange}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
      fullWidth
    >
      <Tab value="all" icon={<Icon>list</Icon>} label="ALL" />
      <Tab value="active" icon={<Icon>question_answer</Icon>} label="ACTIVE" />
      <Tab
        value="completed"
        icon={<Icon>hourglass_full</Icon>}
        label="COMPLETED"
      />
    </Tabs>
  )

  onUserChange = (res) => {
    let user_id = []
    let astrologer_id = []
    res.map((x) => {
      if (x.group_id == 2) astrologer_id.push(x.id)
    })
    res.map((x) => {
      if (x.group_id == 3) user_id.push(x.id)
    })
    this.applyFilter("user_id", user_id.join(","))
    this.applyFilter("astrologer_id", astrologer_id.join(","))
  }

  render() {
    let { rows, isLoading } = this.state
    //if( isLoading && !rows.length ) return <Progress/>
    return (
      <div>
        <UserSearch onChange={this.onUserChange} placeholder="Search users" />

        {this.renderTabs()}
        {rows.map((row, idx) => (
          <ReportBookingListRow
            key={row.requirement_id}
            row={row}
            fetchRows={this.fetchRows}
          />
        ))}
        {rows.length == 0 && <EmptyCard card />}
        {this.renderPagination()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportBookingList))
