import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"

import { instance } from "../../../../utils"
import { Progress } from "../../../../utils/components"
import PhoneTimings from "./timings"
import PhoneRate from "./rate"
import PhoneStatusSwitch from "./statusSwitch"
import PhoneLiveSwitch from "./liveSwitch"

const API = `astrologer/astrologer_phone_service/get_row/`

class PhoneService extends Component {
  state = {
    isLoading: true,
    row: {},
  }

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    this.setState({ isLoading: true })
    let { user_id } = this.props
    instance({
      method: "post",
      url: API,
      data: { filter: { user_id } },
    }).then(({ row }) => {
      this.setState({ isLoading: false, row })
    })
  }

  renderTitle = (txt) => (
    <Typography
      color="primary"
      variant="subtitle1"
      style={{ marginBottom: 10 }}
    >
      {txt}
    </Typography>
  )

  renderAvailability = () => {
    let { row } = this.state
    return (
      <React.Fragment>
        <Divider light style={{ margin: "20px 0" }} />
        {this.renderTitle("LIVE AVAILABILITY")}
        <PhoneLiveSwitch phone_service={row} />
      </React.Fragment>
    )
  }

  render() {
    let { isLoading, row } = this.state
    let { user_id } = this.props
    if (isLoading) return <Progress />
    if (!row) return <h4 style={{ margin: 30 }}>Phone service not added!</h4>
    return (
      <div>
        {this.renderTitle("SERVICE STATUS")}
        <PhoneStatusSwitch row={row} />
        <PhoneRate row={row} />
        {this.renderAvailability()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, { row }) => {
  let user_id = row.id
  return { user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneService))
