import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"

import WalletListRow from "./row"
import WalletLeftCard from "../leftCard"
import { instance } from "../../../../../../utils"
import {
  PaginationComponent,
  Progress,
} from "../../../../../../utils/components"
import { EmptyCard } from "../../../../../../utils/components/misc"
const API = `wallet/get_rows`

class WalletList extends Component {
  state = {
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 4,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { page, limit } = this.state
    let start = page * limit
    let { user } = this.props
    let filter = { user_id: user.id }
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter, order_by: "payment_join_date" },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  renderPagination = () => {
    return (
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    )
  }

  renderContent = () => {
    let { rows, isLoading } = this.state
    if (!Object.values(rows).length)
      return (
        <Card>
          <CardContent>{isLoading ? <Progress /> : <EmptyCard />}</CardContent>
        </Card>
      )

    return (
      <React.Fragment>
        {Object.values(rows).map((row, idx) => {
          return <WalletListRow key={row.payment_id} row={row} />
        })}
        {this.renderPagination()}
      </React.Fragment>
    )
  }

  render() {
    let { rows, isLoading } = this.state
    return (
      <Grid container spacing={16}>
        <Grid item md={12}>
          <Card style={{ marginTop: 10 }}>
            <WalletLeftCard
              row={this.props.row}
              fetchRows={this.props.fetchRows}
            />
          </Card>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant="subheading"
            color="primary"
            style={{ margin: 10, marginTop: 10 }}
          >
            HISTORY
          </Typography>
          {this.renderContent()}
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, { row }) => {
  let user = row
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(WalletList))
