import { instance } from "../../../utils"
const API = `astrologer/astrologer_service/get_service_rate_range`

export function get_service_rate_range(
  service_id,
  mr_service_id = 0,
  user_id,
  cb = null
) {
  instance.post(API, { service_id, mr_service_id, user_id }).then((result) => {
    let { res, min_rate, max_rate } = result
    cb && cb(result)
  })
}
