import React from "react"
import { Typography } from "@material-ui/core"

export default function LiveChat() {
  return (
    <div style={{ marginTop: "60px" }}>
      <Typography variant="h5">
        Search by astrologer name (full name recommended)
      </Typography>
    </div>
  )
}
