import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"

import { updateField } from "../actions"

class RequirementLanguages extends Component {
  handleChange = (event, checked) => {
    let { requirement_languages, requirement_id } = this.props
    if (checked) requirement_languages.push(event.target.value)
    else
      requirement_languages = requirement_languages.filter(
        (x) => x != event.target.value
      )
    this.props.dispatch(
      updateField(
        requirement_id,
        "requirement_languages",
        requirement_languages
      )
    )
  }

  render() {
    return null
    let { languages, classes, requirement_languages, canEdit } = this.props
    return (
      <div>
        <Typography className={classes.name}>language preferences</Typography>
        <Typography>
          {requirement_languages.map((item) => (
            <span key={item} className={classes.language}>
              {languages[item]}
            </span>
          ))}
        </Typography>
      </div>
    )
  }
}

const styles = (theme) => ({
  name: {
    color: "#31708f",
    fontSize: 12,
  },
  language: {
    color: "#04486d",
    fontSize: 13,
    marginRight: 20,
  },
})

const mapStateToProps = (
  { enums, requirement: requirementList },
  { requirement }
) => {
  let { requirement_languages } = requirement
  let languages = enums.rows.languages.vt
  return { languages, requirement_languages }
}
export default connect(mapStateToProps)(
  withStyles(styles)(RequirementLanguages)
)
