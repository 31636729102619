import React, { useState } from "react"
import { CircularProgress } from "@material-ui/core"
import Search from "./Search"
import TotalChats from "./TotalChats"
import NoData from "./NoData"
import ChatInfoCard from "./ChatInfoCard"
import Instructions from "./Instructions"

function Loading() {
  return (
    <div style={{ textAlign: "center", marginTop: "60px" }}>
      <CircularProgress />
    </div>
  )
}

export default function SearchRoot() {
  const [query, setQuery] = useState("")
  const [data, setData] = useState(null)
  const [noData, setNoData] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <div style={{ marginTop: "20px" }}>
      <Search
        data={data}
        setData={setData}
        setLoading={setLoading}
        query={query}
        setQuery={setQuery}
        setNoData={setNoData}
      />
      <TotalChats />
      {loading ? <Loading /> : null}
      {data ? (
        <div>
          {data.map((chatInfo) => (
            <ChatInfoCard key={chatInfo._id} data={chatInfo} />
          ))}
        </div>
      ) : null}
      {noData ? <NoData /> : null}
      {!noData && !data && !loading ? <Instructions /> : null}
    </div>
  )
}
