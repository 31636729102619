import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"

import AstrologerActionsEditProfile from "./editProfile"
import AstrologerActionsSms from "./sms"
import AstrologerActionsStatus from "./status"
import AstrologerActionsDelete from "./delete"
import TestBookingRoot from "../testbooking"

class AstrologerActions extends Component {
  renderStatusButton = () => {
    let { row, astrologer_reg_status, classes, fetchRows } = this.props
    let regCompleted = row.service_status == 1 || row.service_status == 2
    let isActive = row.service_status == 1

    let commonProps = {
      onSuccess: fetchRows,
      row,
      user_id: row.user_id,
    }

    if (regCompleted)
      return (
        <AstrologerActionsStatus
          label={isActive ? `PUT ON HOLD` : `ACTIAVTE`}
          color={isActive ? `red` : `yellow`}
          status={isActive ? 2 : 1}
          {...commonProps}
        />
      )

    let onTestBooking =
      row.astrologer_reg_status == 2 || row.astrologer_reg_status == 3
    let color = false
    let label = false
    let status = 1
    switch (parseInt(row.astrologer_reg_status)) {
      case 1:
      case 2:
        color = "green"
        label = "APROVE PROFILE"
        status = 3
        break
      case 3:
        color = "orange"
        label = "SKIP TEST BOOKING"
        status = 5
        break
      case 4:
        color = "green"
        label = "MARK AS TEST BOOKING PASSED"
        status = 5
        break
      case 5:
        color = "green"
        label = "MARK AS PHONE CALL PASSED"
        status = 6
        break
      case 6:
        color = "green"
        label = "ACTIVATE ACCOUNT"
        status = 8
        break
      case 7:
        color = "green"
        label = "ACTIVATE ACCOUNT"
        status = 8
        break

      default:
        color = "green"
        label = "APROVE PROFILE"
        status = 3
        break
    }

    return (
      <React.Fragment>
        {label && (
          <AstrologerActionsStatus
            label={label}
            color={color}
            status={status}
            regStatus
            {...commonProps}
          />
        )}
        {parseInt(row.astrologer_reg_status) == 2 && (
          <AstrologerActionsStatus
            label={`REJECT VERIFICATION REQUEST`}
            color={`pink`}
            status="1"
            regStatus
            {...commonProps}
          />
        )}
        <AstrologerActionsStatus
          label={`REJECT`}
          color={`red`}
          status="8"
          regStatus
          {...commonProps}
        />
      </React.Fragment>
    )
  }

  renderTestBooking = () => (
    <React.Fragment>
      <Divider />
      <TestBookingRoot user={this.props.row} user_id={this.props.row.user_id} />
    </React.Fragment>
  )

  render() {
    let { row, astrologer_reg_status, classes, fetchRows } = this.props
    let status_text = <Chip label="ON REGISTRATION" color="secondary" />
    if (row.service_status == 1)
      status_text = <Chip label="ACTIVE" color="primary" />
    if (row.service_status == 2)
      status_text = <Chip label="ON HOLD" color="secondary" />
    let onTestBooking =
      row.astrologer_reg_status == 2 ||
      row.astrologer_reg_status == 3 ||
      row.astrologer_reg_status == 4
    return (
      <div style={{ margin: "10px 0", textAlign: "center" }}>
        {status_text}
        {![1, 2].includes(row.service_status) && row.astrologer_reg_status && (
          <Chip label={astrologer_reg_status[row.astrologer_reg_status]} />
        )}
        <Divider style={{ marginTop: 10 }} />
        <div className={classes.buttons}>
          <AstrologerActionsEditProfile row={row} fetchRows={fetchRows} />
          <AstrologerActionsSms row={row} />
          <AstrologerActionsDelete row={row} />
          {this.renderStatusButton()}
        </div>
        {onTestBooking && this.renderTestBooking()}
      </div>
    )
  }
}

const styles = (theme) => ({
  buttons: {
    margin: 10,
    display: "flex",
    justifyContent: "space-around",
  },
})

const mapStateToProps = ({ enums }, ownProps) => {
  let astrologer_reg_status = enums.rows.astrologer_reg_status.vt
  return { astrologer_reg_status }
}
export default connect(mapStateToProps)(withStyles(styles)(AstrologerActions))
