import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_report_service/change_status/`

class ReportStatusSwitch extends Component {
  state = {
    isLoading: false,
    mr_service_status: this.props.row.mr_service_status || 0,
    result: null,
  }

  handleSubmit = (mr_service_status) => {
    this.setState({ isLoading: true, result: null })
    let { dispatch, row } = this.props
    row.mr_service_status = mr_service_status
    row.user_id = this.props.row.user_id
    instance.post(API, row).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      } else this.setState({ mr_service_status })
      dispatch(pushMsg(msg, "success"))
    })
  }

  handleChange = (name) => (event) => {
    this.handleSubmit()
    this.setState({ [name]: event.target.checked ? 1 : 2 })
  }

  render() {
    let { mr_service_status, result, isLoading } = this.state
    let checked = mr_service_status == 1
    let label = isLoading ? "Updating..." : checked ? "Active" : "On Hold"
    return (
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(event) =>
                this.handleSubmit(event.target.checked ? 1 : 2)
              }
              value="checkedA"
            />
          }
          label={label}
        />
        <FormResult result={result} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportStatusSwitch))
