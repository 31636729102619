import React, { Component } from "react"
import { render } from "react-dom"
import Downshift from "downshift"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"

class AutoComplete extends Component {
  render() {
    let { placeholder, style, suggestions, classes, ...rest } = this.props
    return (
      <Downshift itemToString={(item) => (item ? item.value : "")} {...rest}>
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
        }) => (
          <div className={classes.container}>
            <TextField
              {...getInputProps({
                placeholder: placeholder || `Enter text`,
              })}
              fullWidth
              style={style}
            />
            <Paper {...getMenuProps()} className={classes.paper} square>
              {isOpen
                ? suggestions
                    .filter(
                      (item) => !inputValue || item.value.includes(inputValue)
                    )
                    .map((item, index) => (
                      <MenuItem
                        {...getItemProps({
                          key: `${item.value}-${index}`,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? "lightgray"
                                : "white",
                            fontWeight:
                              selectedItem === item ? "bold" : "normal",
                          },
                        })}
                      >
                        {item.label}
                      </MenuItem>
                    ))
                : null}
            </Paper>
          </div>
        )}
      </Downshift>
    )
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
})
export default withStyles(styles)(AutoComplete)
