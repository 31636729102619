import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

class ReportRow extends Component {
  render() {
    let { title, text, classes } = this.props
    return (
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemText secondary={text || `Not added!`} primary={title} />
        </ListItem>
      </List>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #efe7f5",
  },
  inline: {
    display: "inline",
  },
})

const mapStateToProps = ({ auth }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportRow))
