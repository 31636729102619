import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"

import ModalComponent from "../../../utils/components/modal"
import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"
import { Progress } from "../../../utils/components"
import BirthDetails from "./birthDetails"
import ChatRoot from "../../chat"
import TestBookingSolution from "./solution"

const API_FETCH_ROW = `astrologer/astrologer_test_booking/get_row`
const SAVE_API = `astrologer/astrologer_test_booking/update`

const shapeArrayColumn = (column) => {
  if (column instanceof Array) return column
  if (column == null || column == undefined || column == "") return []
  return column.split(",").map((item) => `${item}`)
}

class TestBookingRoot extends Component {
  state = {
    isLoading: true,
    result: false,
    row: {},
    reqAdded: false,
    modalOpen: false, //this.props.user_id == 1188
  }

  _setState = (newState) => this.setState(newState)

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    let { user_id } = this.props
    instance({
      method: "post",
      url: API_FETCH_ROW,
      data: { filter: { astrologer_id: user_id } },
    }).then((result) => {
      let { row, res } = result
      if (row == null) row = { requirement_bds: "" }
      row.requirement_bds = shapeArrayColumn(row.requirement_bds)
      this.setState({
        isLoading: false,
        row,
        reqAdded:
          row.requirement_requirement != "" &&
          row.requirement_requirement !== undefined,
      })
    })
  }

  handleSubmit = () => {
    let { user_id } = this.props
    let { row } = this.state
    row.astrologer_id = user_id
    this.setState({ isLoading: true, result: null })
    instance({
      method: "post",
      url: SAVE_API,
      data: row,
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        this.props.dispatch(pushMsg(`Success !`, "success"))
        this.fetchRow()
      }
    })
  }

  renderIntercation = () => {
    let { isLoading, result, row } = this.state
    let solutionAdded = row.requirement_status > 5
    return (
      <React.Fragment>
        <Typography color="primary" variant="h5" style={{ margin: 20 }}>
          REQUIREMENT
        </Typography>
        <BirthDetails requirement_bds={this.state.row.requirement_bds} />
        <Typography
          style={{
            margin: "10px 0px 20px",
            padding: 20,
            border: "1px solid #b3a4b9",
          }}
        >
          {this.state.row.requirement_requirement}
        </Typography>
        <ChatRoot
          chat_reference={`test_booking_requirement_${row.requirement_id}`}
          receiver_id={row.user_id}
          canEdit={!solutionAdded}
          defaultMsg="If you have something to ask admin ?"
        />
        <Typography color="primary" variant="h5" style={{ margin: 20 }}>
          REPORT
        </Typography>
        <TestBookingSolution row={row} fetchRow={this.fetchRow} />
        <ChatRoot
          chat_reference={`test_booking_solution_${row.requirement_id}`}
          receiver_id={row.user_id}
          canEdit={solutionAdded}
          defaultMsg="If you have something to ask admin ?"
        />
      </React.Fragment>
    )
  }

  renderContent = () => {
    let { isLoading, result, row, reqAdded } = this.state
    console.log(row)
    let solutionAdded = row.requirement_status > 5
    console.log("row.requirement_requirement", row.requirement_requirement)
    if (reqAdded) return this.renderIntercation()
    return (
      <React.Fragment>
        <BirthDetails
          requirement_bds={this.state.row.requirement_bds}
          onChange={(requirement_bds) => {
            let { row } = this.state
            row.requirement_bds = requirement_bds
            this.setState({ row })
          }}
        />
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={this.state.row.requirement_requirement}
          onChange={(event) => {
            let { row } = this.state
            row.requirement_requirement = event.target.value
            this.setState({ row })
          }}
        />
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          fullWidth
          color="secondary"
          label="SUBMIT"
          onClick={this.handleSubmit}
        />
      </React.Fragment>
    )
  }

  render() {
    let { user, user_id } = this.props
    let { isLoading, row } = this.state
    //if( isLoading ) return <Progress/>
    return (
      <div style={{ padding: 20 }}>
        {!row && (
          <Typography>This astrologer is waiting for test booking! </Typography>
        )}
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => this.setState({ modalOpen: true })}
        >
          {!row ? `Assign Testbooking` : `View Test booking`}
        </Button>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Test Booking"
          content={() => this.renderContent()}
        />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let report_booking_status = enums.rows.report_booking_status.vt
  return { report_booking_status }
}
export default connect(mapStateToProps)(withStyles(styles)(TestBookingRoot))
