import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import ModalComponent from "../../../utils/components/modal"

import ProfileRoot from "../profile"
import ServicesRoot from "../services"
import AstrologerListCard from "../list/card"
import ProfileBank from "../profile/components/bank"
import AstrologerProfileWallet from "../profile/components/wallet"

class AstrologerActionsEditProfile extends Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  renderContent = () => (
    <React.Fragment>
      <AstrologerListCard row={this.props.row} />
      <ProfileRoot {...this.props.row} />
      <ServicesRoot row={this.props.row} />
      <AstrologerProfileWallet
        row={this.props.row}
        fetchRows={this.props.fetchRows}
      />
      <Typography
        variant="subheading"
        color="primary"
        style={{ margin: "20px 0" }}
      >
        Bank Details
      </Typography>
      <ProfileBank row={this.props.row} />
    </React.Fragment>
  )

  render() {
    return (
      <React.Fragment>
        <IconButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => this.setState({ modalOpen: true })}
        >
          <Icon>apps</Icon>
        </IconButton>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Edit Profile"
          content={() => this.renderContent()}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(AstrologerActionsEditProfile)
)
