import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"
import { getUploadImge, timeAgo } from "../../../utils/functions"

import { updateField } from "../actions"
import BirthDetails from "../../birthdetails"
import RequirementLanguages from "./languages"

const styles = (theme) => ({
  container: {
    padding: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
})

class RequirementBlock extends Component {
  renderRequirement = () => {
    let { onEdit, requirement, classes, dispatch } = this.props
    if (!requirement) return null
    if (onEdit)
      return (
        <TextField
          label="Requirement"
          className={classes.textField}
          value={requirement.requirement_requirement}
          onChange={(event) =>
            dispatch(
              updateField(
                requirement.requirement_id,
                "requirement_requirement",
                event.target.value
              )
            )
          }
          multiline
          rows="3"
        />
      )
    else return <Typography>{requirement.requirement_requirement}</Typography>
  }
  render() {
    let { requirement_id, classes, onEdit, requirement } = this.props
    return (
      <Card>
        <CardContent>
          <BirthDetails requirement={requirement} canEdit={onEdit} />
          <Divider variant="middle" className={classes.divider} />
          {this.renderRequirement()}
          <div style={{ marginTop: 20 }}>
            <RequirementLanguages requirement={requirement} canEdit={onEdit} />
            {requirement.report_astrologer_read_time && (
              <Typography component="span" color="secondary">
                <Icon style={{ fontSize: 14 }}>done_all</Icon>{" "}
                {timeAgo(requirement.report_astrologer_read_time)}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = ({ requirement: requirementList }) => {
  return {}
}

RequirementBlock.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(withStyles(styles)(RequirementBlock))
