import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { withRouter } from "react-router-dom"

import UserGroupFetcher from "../../users/groupFetcher"
import { ConfirmationDialog, ModalComponent } from "../../../utils/components"
import {
  getUploadImge,
  convertMysqlDateTime,
  createUrl,
  callDuration,
} from "../../../utils/functions"
import PhonecallUpdator from "../components/updator"
import PhonecallInteractionDetails from "../interaction/details"
import PhonecallCancelButton from "./cancelButton"

class PhonecallListRow extends Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  showBooking = () => this.setState({ modalOpen: true })

  fetchRows = () => this.props.fetchRows()

  renderContent = () => {
    const { classes, row, phone_call_status, users } = this.props
    let astrologer = users.rows[row.astrologer_id]
    let user = users.rows[row.user_id]

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            src={getUploadImge(user ? user.image : null, "user/avatar")}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <UserGroupFetcher visualise ids={[row.user_id]}>
            {!user ? (
              <Typography>User loading...</Typography>
            ) : (
              <Typography className={classes.name} style={{ color: "blue" }}>
                {`${user.first_name} ${user.last_name}`}
              </Typography>
            )}
          </UserGroupFetcher>
          <UserGroupFetcher ids={[row.astrologer_id]}>
            {!astrologer ? (
              <Typography>User loading...</Typography>
            ) : (
              <Typography className={classes.name}>
                {`${astrologer.first_name} ${astrologer.last_name}`}
              </Typography>
            )}
          </UserGroupFetcher>
          <Typography variant="caption" color="textSecondary">
            #{row.phonecall_id}
          </Typography>
          <Typography color="primary" variant="subheading">
            Duration : {callDuration(row.call_duration)}
          </Typography>

          <Typography variant="body1" color="secondary">
            {phone_call_status[row.call_status]}
          </Typography>
          <Typography color="textSecondary">
            {convertMysqlDateTime(row.call_join_date, true)}
          </Typography>
          <PhonecallUpdator
            visualise
            row={row}
            call_sid={row.call_sid}
            updateRow={(row) => this.setState({ row })}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    let { classes, row } = this.props
    return (
      <React.Fragment>
        <Card style={{ marginTop: 5, marginBottom: 5 }}>
          <CardContent>{this.renderContent()}</CardContent>
          <CardActions
            disableActionSpacing
            style={{ justifyContent: "flex-end", paddingTop: 0 }}
          >
            <PhonecallCancelButton row={row} fetchRows={this.fetchRows} />
            <Button
              className={classes.actionButton}
              color="primary"
              size="small"
              onClick={this.showBooking}
            >
              View <Icon>chevron_right</Icon>
            </Button>
          </CardActions>
        </Card>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Phone Calling"
          content={() => <PhonecallInteractionDetails row={row} />}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  avatar: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
    maxWidth: 120,
  },
  leftGrid: {
    padding: 10,
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  name: {
    color: "#31708f",
    fontSize: 18,
  },
  actionButton: {
    padding: "0 20px",
  },
})

const mapStateToProps = ({ users, enums }, { row }) => {
  let phone_call_status = enums.rows.phone_call_status.vt
  return {
    users,
    phone_call_status,
  }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PhonecallListRow))
)
