import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"

import ChatRoot from "../../chat"
import DisputeRoot from "../../dispute"
import ReviewRoot from "../../review"
import { convertMysqlDateTime } from "../../../utils/functions"
import { instance } from "../../../utils"
import PhonecallUpdator from "../components/updator"
import { callDuration } from "../../../utils/functions"
const API = `phonecall/update_field`

class PhonecallInteractionDetails extends Component {
  componentWillMount = () => {}

  renderDispute = () => {
    let { phonecall_id, dispute_status, astrologer_id } = this.props.row
    return (
      <div>
        <DisputeRoot
          dispute_status={dispute_status}
          dispute_ref_id={phonecall_id}
          service_id={1}
          astrologer_id={astrologer_id}
          onSuccess={this.onDisputeSuccess}
        />
      </div>
    )
  }

  onDisputeSuccess = () => {
    let { updateRow } = this.props
    updateRow({ dispute_status: 2 })
    let data = {
      field: "dispute_status",
      value: 2,
      id: this.props.row.phonecall_id,
    }
    instance({
      method: "post",
      url: API,
      data,
    })
  }

  renderReview = () => {
    let { phonecall_id, astrologer_id } = this.props.row
    return (
      <div>
        <Typography
          variant="subheading"
          color="secondary"
          style={{ margin: 10, marginTop: 20 }}
        >
          REVIEW
        </Typography>
        <ReviewRoot
          reference_id={phonecall_id}
          service_id={1}
          astrologer_id={astrologer_id}
        />
      </div>
    )
  }

  renderContent = () => {
    let { row } = this.props
    return (
      <Card style={{ margin: "10px 0", textAlign: "left" }}>
        <CardContent>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              Date
            </Grid>
            <Grid item xs={8}>
              {convertMysqlDateTime(row.call_join_date, true)}
            </Grid>
            <Grid item xs={4}>
              Duration
            </Grid>
            <Grid item xs={8}>
              {callDuration(row.call_duration)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  render() {
    let { row } = this.props
    return (
      <div>
        {this.renderContent()}
        <PhonecallUpdator visualise call_sid={row.call_sid} />
        {row.call_duration > 60 && this.renderDispute()}
        {row.call_duration > 60 * 5 && this.renderReview()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallInteractionDetails)
)
