import React from "react"
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  withStyles,
  Slide,
  DialogContent,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function ModalComponent(props) {
  const {
    classes,
    content,
    isChatModalOpen,
    setIsChatModalOpen,
    astrologerName,
    userName,
  } = props

  function handleClose() {
    setIsChatModalOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isChatModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div className={classes.flex}>
              <Typography variant="subtitle1" color="inherit">
                {astrologerName} with {userName}
              </Typography>
            </div>
            <IconButton color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent id="scrollable" className={classes.scrollableDiv}>
          <div>{content && content()}</div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    background: "#f8f8f8",
    overflowX: "hidden",
    padding: theme.spacing.unit,
    minHeight: "92vh",
  },
  flex: {
    flex: 1,
  },
  scrollableDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputMsgForm: {
    flex: 1,
    display: "flex",
  },
  inputMsg: {
    flex: 1,
  },
})

export default withStyles(styles)(ModalComponent)
