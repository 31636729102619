import React, { useState, useEffect } from "react"
import { CircularProgress, Typography, Button } from "@material-ui/core"
import ChatInfoCard from "./LogsChatInfoCard"
import { apiv2Instance } from "../../../utils"

export default function Logs() {
  const [logs, setLogs] = useState(null)
  const [loading, setLoading] = useState(true)
  const [extraLoading, setExtraLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [nextPageRef, setNextPageRef] = useState(1)
  const [lastLogRef, setLastLogRef] = useState(null)

  async function loadMoreLogs() {
    try {
      setExtraLoading(true)
      const { data } = await apiv2Instance.get(
        `/admin/chat/logs?lastLogRef=${lastLogRef}&page=${nextPageRef}`
      )
      const { sessions, hasMore, nextPage } = data
      const { _id } = sessions[sessions.length - 1]
      setLogs([...logs, ...sessions])
      setHasMore(hasMore)
      setNextPageRef(nextPage)
      setLastLogRef(_id)
    } catch (e) {
      console.error(e)
    } finally {
      setExtraLoading(false)
    }
  }

  useEffect(() => {
    ;(async function () {
      try {
        const { data } = await apiv2Instance.get("/admin/chat/logs")
        const { sessions, hasMore, nextPage } = data
        const { _id } = sessions[sessions.length - 1]
        setLogs(sessions)
        setHasMore(hasMore)
        setNextPageRef(nextPage)
        setLastLogRef(_id)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <CircularProgress />
      </div>
    )
  }

  if (Array.isArray(logs) && logs.length === 0) {
    return (
      <div>
        <Typography>No data</Typography>
      </div>
    )
  }

  return (
    <div style={{ margin: "0 auto", marginTop: "20px" }}>
      {logs.map((session) => (
        <ChatInfoCard key={session._id} data={session} />
      ))}
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        {hasMore ? (
          <Button
            color="primary"
            variant="contained"
            onClick={loadMoreLogs}
            disabled={extraLoading}
          >
            Load More{" "}
            {extraLoading ? (
              <CircularProgress
                size={16}
                style={{ margin: "-4px 0px 0px 6px" }}
              />
            ) : null}
          </Button>
        ) : (
          <Typography variant="title">-- No more data --</Typography>
        )}
      </div>
    </div>
  )
}
