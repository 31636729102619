import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"
import ModalComponent from "../../../../utils/components/modal"
import PhoneNextSlot from "./nextSlot"

const API = `astrologer/astrologer_phone_availability/change_slot_status/`

class PhoneLiveSwitch extends Component {
  state = {
    isLoading: false,
    result: null,
    modalOpen: false,
    phone_service: this.props.phone_service,
  }

  _setState = (newState) => this.setState(newState)

  handleSubmit = (phone_service_slot_status) => {
    this.setState({ isLoading: true, result: null })
    let { dispatch } = this.props
    let { phone_service } = this.state
    instance
      .post(API, { phone_service_slot_status, user_id: phone_service.user_id })
      .then((result) => {
        this.setState({ isLoading: false })

        let { res, msg } = result
        if (res != 1) {
          this.setState({ result })
          return
        }
        phone_service.phone_service_slot_status = phone_service_slot_status
        this.setState({ phone_service, modalOpen: true })
        dispatch(pushMsg(msg, "success"))
        // refresh data
      })
  }

  handleChange = (name) => (event) => {
    this.handleSubmit()
    this.setState({ [name]: event.target.checked ? 1 : 2 })
  }

  render() {
    let { result, isLoading } = this.state
    let { phone_service } = this.props
    if (!phone_service) return null
    let checked =
      phone_service.phone_service_status == 1 &&
      phone_service.phone_service_slot_status == 1
    let label = isLoading ? (
      "Updating..."
    ) : checked ? (
      <Typography style={{ color: "green" }}>
        Available to take phonecalls
      </Typography>
    ) : (
      <Typography color="error">Not available for phonecalls</Typography>
    )
    return (
      <div style={{ padding: 20 }}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(event) =>
                this.handleSubmit(event.target.checked ? 1 : 2)
              }
              value="checkedA"
            />
          }
          label={label}
        />
        <FormResult result={result} />
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="TIMINGS"
          content={() => (
            <PhoneNextSlot
              _setState={this._setState}
              phone_service={phone_service}
            />
          )}
        />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth }, { phone_service }) => {
  return { phone_service }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneLiveSwitch))
