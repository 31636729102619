import React from "react"
import { Typography } from "@material-ui/core"
import DataTable from "./components/DataTable"

export default function Analytics() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4">Analytics</Typography>
      <DataTable />
    </div>
  )
}
