import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { Progress } from "../../utils/components"
import { logOut } from "./actions"

class LogoutPage extends Component {
  componentWillMount = () => {
    let { history, dispatch } = this.props
    dispatch(logOut())
  }

  render() {
    return <Progress loading="true" />
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(LogoutPage))
)
