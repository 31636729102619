import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import ChatForm from "./components/form"
import ChatList from "./components/list"

import { instance } from "../../utils"
const FETCH_ROWS_API = `chat/get_rows`
class ChatRoot extends Component {
  state = {
    isLoading: true,
    rows: {},
    total: 0,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { chat_reference } = this.props
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: FETCH_ROWS_API,
      data: { filter: { chat_reference } },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        this.setState({
          rows: result.rows,
          total: Object.values(result.rows).length,
        })
      }
    })
  }

  render() {
    let { chat_reference, receiver_id, defaultMsg, canEdit } = this.props
    let { rows, isLoading, total } = this.state
    if (!canEdit && !total) return null
    return (
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          <ChatList rows={rows} isLoading={isLoading} />
          {!isLoading && !total && false && (
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ padding: 10, border: "1px solid #ccc" }}
            >
              {defaultMsg}
            </Typography>
          )}
          {canEdit && (
            <ChatForm
              receiver_id={receiver_id}
              chat_reference={chat_reference}
              onSuccess={this.fetchRows}
            />
          )}
        </CardContent>
      </Card>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ChatRoot))
