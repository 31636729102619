import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { Progress } from "../../utils/components"
import { fetchRow } from "./actions"

const MyContext = React.createContext()

class UserFetcher extends Component {
  componentWillMount = () => {
    let { id, user, isLoading, dispatch } = this.props
    if (!user && !isLoading) dispatch(fetchRow(id))
  }

  render() {
    let { isLoading, user, visualise } = this.props
    if (!user || user == undefined || user == null)
      return visualise ? <Progress /> : null
    const { children, ...otherProps } = this.props
    const childrens = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        index,
        user,
      })
    })
    return <React.Fragment>{childrens}</React.Fragment>
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ users }, { id }) => {
  let isLoading = users.idsLoading.includes(id)
  let exists = users.ids.includes(id)
  let user = exists ? users.rows[id] : null
  return { user, isLoading }
}
export default connect(mapStateToProps)(withStyles(styles)(UserFetcher))
