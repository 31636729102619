import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"

import { getUploadImge } from "../../../utils/functions"

class UserListCard extends Component {
  renderContent = () => {
    const { classes, row } = this.props
    if (!row) return null
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={16}
        style={{ margin: 20 }}
      >
        <Grid item xs={2} className={classes.leftGrid}>
          <Avatar
            className={classes.avatar}
            src={getUploadImge(row.image, "user/avatar")}
            classes={{ img: classes.avatarImg, root: classes.avatarRoot }}
          />
        </Grid>
        <Grid item xs={10} className={classes.rightGrid}>
          <div className={classes.content}>
            <Typography color="primary" variant="subheading">
              {`${row.first_name} ${row.last_name || ""}`}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {row.email}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {row.phone}
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    return <div className={classes.card}>{this.renderContent()}</div>
  }
}

UserListCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}
const mapStateToProps = ({ enums }) => {
  return {}
}

const styles = (theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "#fff",
    border: "1px solid #efe2e2",
  },
  avatarImg: {
    margin: "10px auto",
  },
  avatarRoot: {
    margin: "0 auto",
  },
  leftGrid: {
    textAlign: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    width: "90%",
    borderRadius: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  actions: {
    textAlign: "center",
    margin: 0,
  },
})

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(UserListCard)
)
