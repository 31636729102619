import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import ListItemText from "@material-ui/core/ListItemText"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    background: "#f8f8f8",
    overflowX: "hidden",
    padding: theme.spacing.unit * 2,
    minWidth: 300,
  },
  flex: {
    flex: 1,
  },
  content: {
    maxWidth: 1200,
    margin: "0 auto",
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ModalPop extends React.Component {
  state = {
    modalOpen: false,
  }

  handleClickOpen = () => {
    this.props._setState({ modalOpen: true })
  }

  handleClose = () => {
    this.props._setState({ modalOpen: false })
  }

  renderContent = () => null

  renderHeader = () => (
    <AppBar className={this.props.classes.appBar} color="inherit">
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={this.handleClose}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" className={this.props.flex}>
          {this.props.title || `-`}
        </Typography>
      </Toolbar>
    </AppBar>
  )

  render() {
    const { classes, activator, content } = this.props
    return (
      <Dialog
        open={this.props.modalOpen}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        {this.renderHeader()}
        <Divider />
        <div className={classes.root}>{content && content()}</div>
      </Dialog>
    )
  }
}

ModalPop.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ModalPop)
