import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import { createUrl } from "../../../../../utils/functions"
import { instance } from "../../../../../utils"
import { pushMsg } from "../../../../../navigation/actions"

const API = `wallet/wallet_admin/update_wallet`

class WalletForm extends Component {
  state = {
    payment_amount: 0,
    payment_remarks: "",
    paying: true,
    isLoading: false,
  }

  handleProceed = () => {
    let { history, dispatch, onSuccess, user_id } = this.props
    let { payment_amount, payment_remarks, paying } = this.state
    if (!parseInt(payment_amount)) {
      dispatch(pushMsg("Please enter amount", "error"))
      return
    }
    if (!paying) payment_amount = payment_amount * -1
    let data = { user_id, payment_amount, payment_remarks }
    this.setState({ isLoading: true })
    instance.post(API, data).then((result) => {
      let { res, msg } = result
      dispatch(pushMsg(msg))
      if (res == 1) onSuccess && onSuccess()
    })
  }

  render() {
    let { classes } = this.props
    let { paying, payment_amount, payment_remarks } = this.state
    return (
      <Card style={{ padding: 30, marginTop: 10 }}>
        <FormControlLabel
          control={
            <Switch
              checked={paying}
              onChange={(event) =>
                this.setState({ paying: event.target.checked })
              }
              value="checkedA"
            />
          }
          label="Paying to account"
        />
        <TextField
          fullWidth
          value={payment_amount || ``}
          onChange={(event) =>
            this.setState({ payment_amount: parseInt(event.target.value) })
          }
          placeholder="Enter amount to recharge"
        />
        <TextField
          fullWidth
          value={payment_remarks || ``}
          onChange={(event) =>
            this.setState({ payment_remarks: event.target.value })
          }
          placeholder="Remarks"
        />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={this.handleProceed}
          className={classes.proceedButton}
        >
          proceed
        </Button>
      </Card>
    )
  }
}

const styles = (theme) => ({
  proceedButton: {
    marginTop: 20,
    color: "#fff",
    backgroundColor: "#37be5f",
  },
})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(WalletForm))
)
