import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"

import ReportSolution from "../solution"
import RequirementBlock from "../block"
import ChatRoot from "../../chat"
import DisputeRoot from "../../dispute"
import ReviewRoot from "../../review"
import { saveReqRowDB, fetchReqRowDB, updateField } from "../../report/actions"
import InteractionCompleteButton from "../actions/complete"

class ReportInteraction extends Component {
  state = {
    onEdit: false,
    canEdit: false,
  }

  renderRequirementActions = () => {
    let { onEdit, canEdit } = this.state
    let { dispatch, requirement } = this.props
    if (requirement.report_astrologer_read_date !== null) return null
    if (!onEdit)
      return (
        <Button
          fullWidth
          variant="outlined"
          onClick={() => this.setState({ onEdit: true })}
          style={{ color: "blue", textAlign: "right" }}
        >
          <Icon>edit</Icon>
          Edit Booking
        </Button>
      )
    if (onEdit)
      return (
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            dispatch(saveReqRowDB(requirement))
            this.setState({ onEdit: false })
          }}
          style={{ color: "green", textAlign: "right" }}
        >
          Save
        </Button>
      )
  }

  renderReport = () => {
    let {
      solutionAdded,
      requirement_id,
      requirement,
      requirement_status,
      dispatch,
    } = this.props
    let dispute_status = parseInt(requirement.dispute_status)
    return (
      <div>
        <Typography
          variant="subheading"
          color="primary"
          style={{ margin: 10, marginTop: 20 }}
        >
          REPORT
        </Typography>
        <ReportSolution requirement={requirement} />
        {solutionAdded && (
          <ChatRoot
            chat_reference={`solution_${requirement_id}`}
            receiver_id={requirement.astrologer_id}
            canEdit={requirement_status < 9}
            defaultMsg="If you have something to say astrologer more ?"
          />
        )}
        {dispute_status < 5 && dispute_status > 0 && (
          <DisputeRoot
            dispute_status={dispute_status}
            dispute_ref_id={requirement_id}
            service_id={2}
            astrologer_id={requirement.astrologer_id}
            onSuccess={() =>
              dispatch(updateField(requirement_id, "dispute_status", 2))
            }
          />
        )}
        {requirement_status >= 9 && (
          <div>
            <Typography
              variant="subheading"
              color="secondary"
              style={{ margin: 10, marginTop: 20 }}
            >
              REVIEW
            </Typography>
            <ReviewRoot
              reference_id={requirement_id}
              service_id={2}
              astrologer_id={requirement.astrologer_id}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    let { requirement_id, requirement_status, requirement, solutionAdded } =
      this.props
    let { onEdit } = this.state
    return (
      <React.Fragment>
        <RequirementBlock requirement={requirement} onEdit={onEdit} />
        {this.renderRequirementActions()}
        <ChatRoot
          chat_reference={`requirement_${requirement_id}`}
          receiver_id={requirement.astrologer_id}
          canEdit={!solutionAdded}
          defaultMsg="If you have something to say astrologer more ?"
        />
        {this.renderReport()}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement: requirementList }, { requirement }) => {
  let { requirement_id } = requirement
  let requirement_status = requirement
    ? parseInt(requirement.requirement_status)
    : 0
  let solutionAdded = requirement_status > 5
  return { requirement, requirement_id, requirement_status, solutionAdded }
}
export default connect(mapStateToProps)(withStyles(styles)(ReportInteraction))
