import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_profile/update_bio`

class ProfileBiography extends Component {
  state = {
    isLoading: false,
    biography: "",
    result: null,
  }

  componentWillMount = () => {
    let { row, clients } = this.props
    this.setState({ biography: row.biography })
  }

  handleChange = (name) => (event) =>
    this.setState({ [name]: event.target.value })

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { biography } = this.state
    let { dispatch, row } = this.props
    instance.post(API, { biography, user_id: row.user_id }).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
    })
  }

  renderBiography = () => {
    let { classes } = this.props
    let { isLoading, result, biography } = this.state
    return (
      <div>
        <TextField
          multiline
          key={"biography"}
          label={`Biography`}
          className={classes.textField}
          value={biography}
          onChange={this.handleChange("biography")}
          fullWidth
        />
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          label="Save"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
      </div>
    )
  }

  render() {
    return this.renderBiography()
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let clients = enums.rows.clients.vt
  return { clients }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileBiography))
