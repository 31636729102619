import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import msgReducer from "../navigation/layouts/msg/reducer"
import enumsReducer from "./enums/reducer"
import authReducer from "../modules/auth/reducer"
import userReducer from "../modules/users/reducer"

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    msg: msgReducer,
    enums: enumsReducer,
    auth: authReducer,
    users: userReducer,
  })
