import React from "react"
import { withStyles } from "@material-ui/core/styles"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const styles = {
  card: {
    maxWidth: 275,
  },
}

export default function cardWrapper(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      let cardStyles = {
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
      }
      return (
        <Card style={cardStyles}>
          <CardContent>
            <WrappedComponent {...this.props} />
          </CardContent>
        </Card>
      )
    }
  }
}
