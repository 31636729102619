import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import ReportRow from "./row"
import { instance } from "../../../../utils"
import { Progress } from "../../../../utils/components"

const API = `astrologer/astrologer_report_service/get_rows`

class ReportService extends Component {
  state = {
    isLoading: true,
    rows: [],
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    this.setState({ isLoading: true })
    let { user_id } = this.props
    instance({
      method: "post",
      url: API,
      data: { filter: { user_id } },
    }).then(({ rows }) => {
      this.setState({ isLoading: false, rows })
    })
  }

  render() {
    let { report_services } = this.props
    let { isLoading, rows } = this.state
    if (isLoading) return <Progress />

    return (
      <div>
        {Object.keys(report_services).map((x) => (
          <React.Fragment key={x}>
            <Typography color="primary" variant="subtitle1" gutterBottom>
              {report_services[x]}
            </Typography>
            <ReportRow
              row={
                Object.values(rows).find((xr) => xr.mr_service_id === x) || {
                  mr_service_id: x,
                }
              }
            />
            <Divider style={{ margin: "20px 0" }} light />
          </React.Fragment>
        ))}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums, auth }, { row }) => {
  let user_id = row.id
  let report_services = enums.rows.report_services.vt
  return { report_services, user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(ReportService))
