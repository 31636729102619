import React, { useState } from "react"
import {
  withStyles,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core"
import Modal from "./chat/components/Modal"
import ChatScreen from "./chat/components/ChatScreen"

const styles = {
  card: {
    maxWidth: 345,
    marginBottom: "20px",
  },
}

function ChatInfoCard(props) {
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)
  const { classes, data } = props

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {data.astrologerName} with {data.userName}
          </Typography>
          <Typography component="p" style={{ padding: "5px" }}>
            {`${new Date(data.startTime).toLocaleDateString()} ` +
              new Date(data.startTime).toLocaleString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }) +
              ` for ${data.totalTime} minutes`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={(_e) => setIsChatModalOpen(true)}
          >
            Chats
          </Button>
        </CardActions>
      </Card>
      <Modal
        isChatModalOpen={isChatModalOpen}
        setIsChatModalOpen={setIsChatModalOpen}
        classes={classes}
        astrologerName={data.astrologerName}
        userName={data.userName}
        content={() => (
          <ChatScreen userId={data.userId} chatRoomId={data.chatRoomRef} />
        )}
      />
    </>
  )
}

export default withStyles(styles)(ChatInfoCard)
