import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import WalletList from "./list"

class AstrologerProfileWallet extends Component {
  render() {
    let { row, fetchRows } = this.props
    return (
      <div>
        <WalletList row={row} fetchRows={fetchRows} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(AstrologerProfileWallet)
)
