import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Badge from "@material-ui/core/Badge"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import { fade } from "@material-ui/core/styles/colorManipulator"
import { withStyles } from "@material-ui/core/styles"
import AccountCircle from "@material-ui/icons/AccountCircle"
import MailIcon from "@material-ui/icons/Mail"
import NotificationsIcon from "@material-ui/icons/Notifications"
import MoreIcon from "@material-ui/icons/MoreVert"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"

import LeftMenu from "./leftMenu"

const drawerWidth = 340

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
  },
  title: {
    display: "block",
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  subMenu: {
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  globeButton: {
    marginRight: 0,
    paddingRight: 0,
    color: "#fff",
  },
})

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.handleMobileMenuClose()
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state
    const { classes, auth, history } = this.props
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    return (
      <React.Fragment>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <LeftMenu />
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              Monkvyasa
            </Typography>
            <div className={classes.grow} />
            <div>
              <Button variant="text" className={classes.globeButton}>
                <img src="/img/india-flag-16.png" />
                <Icon style={{ fontSize: 16 }}>keyboard_arrow_down</Icon>
              </Button>
              {auth.isLoggedIn && (
                <IconButton color="inherit">
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={() => history.push("profile")}
                color="inherit"
              >
                <Icon>person</Icon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth }, ownProps) => {
  return { auth }
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Header)))
