import React, { Component } from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core"
import { FormResult } from "../../../utils/form"
import { apiv2Instance, instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"

const API_SERVICE = `astrologer/astrologer_admin/change_status/`
const API_REGISTRATION = `astrologer/astrologer_registration/change_status/`

class AstrologerActionsStatus extends Component {
  state = {
    modalOpen: false,
    isLoading: false,
    result: null,
  }

  handleClick = async () => {
    try {
      this.setState({ isLoading: true, result: null })
      const { dispatch, user_id, status, regStatus, onSuccess, row } =
        this.props
      const API = regStatus ? API_REGISTRATION : API_SERVICE
      const result = await instance.post(`${API}${status}`, { user_id })
      if (result.res !== 1) {
        this.setState({ result })
        return
      }
      if (parseInt(status) === 8) {
        await apiv2Instance.post("/admin/astrologer/register", {
          id: user_id,
          firstName: row.first_name,
          phoneNum: `${row.country_code}${row.phone}`,
        })
      }
      if (parseInt(status) === 2) {
        await apiv2Instance.put("/admin/astrologer/status", {
          id: user_id,
          isAvailable: false,
          isUsingApp: false,
        })
      }
      dispatch(pushMsg(result.msg, "success"))
      onSuccess && onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { label, color } = this.props
    const { result, isLoading } = this.state
    return (
      <React.Fragment>
        <Button
          variant="text"
          size="small"
          style={{ color: color || `#ccc` }}
          disabled={isLoading}
          onClick={this.handleClick}
        >
          {isLoading ? `Processing...` : label}
        </Button>
        <FormResult result={result} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (_state, _ownProps) => {
  return {}
}

export default connect(mapStateToProps)(AstrologerActionsStatus)
