import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import DisputeForm from "./components/form"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import ChatRoot from "../chat"
import { instance } from "../../utils"
import { pushMsg } from "../../navigation/actions"
import { FormResult, FormButton } from "../../utils/form"

const FETCH_ROW_API = `dispute/get_row`
const DISPUTE_CLOSE_API = `dispute/close`
class DisputeRoot extends Component {
  state = {
    isLoading: true,
    row: null,
    onEdit: false,
    result: null,
    actionLoading: false,
  }

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    let { dispute_ref_id, service_id } = this.props
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: FETCH_ROW_API,
      data: { filter: { dispute_ref_id, service_id } },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        let row = result.row
        this.setState({ row })
      }
    })
  }

  renderBlock = (row) => (
    <div>
      <Typography variant="subheading">
        {this.props.dispute_titles[row.dispute_title]}
      </Typography>
      <Typography color="textSecondary" paragraph>
        {row.dispute_descritption}
      </Typography>
    </div>
  )

  renderChat = () => {
    let { onEdit, row } = this.state
    let { service_id, dispute_ref_id, astrologer_id } = this.props
    if (row == null) return
    return (
      <ChatRoot
        chat_reference={`dispute_${service_id}_${dispute_ref_id}`}
        receiver_id={astrologer_id}
        canEdit={parseInt(row.dispute_status) == 2}
        defaultMsg="Chat on dispute here."
      />
    )
  }

  closeDispute = () => {
    let { dispatch } = this.props
    this.setState({ actionLoading: true, result: null })
    instance({
      method: "post",
      url: DISPUTE_CLOSE_API,
      data: { dispute_id: this.state.row.dispute_id },
    }).then((result) => {
      this.setState({ actionLoading: false })
      this.setState({ result })
      if (result.res == 1) {
        this.fetchRow()
        dispatch(pushMsg("Thank you", "success"))
      }
    })
  }

  renderActions = () => {
    let { actionLoading, result, row } = this.state
    if (parseInt(row.dispute_status) != 2) return null
    return (
      <div style={{ padding: 20 }}>
        <Typography align="center" variant="caption">
          If you are satisfied with the response on dispute please terminate
          this.
        </Typography>
        <FormResult result={result} />
        <FormButton
          loading={actionLoading}
          fullWidth
          label="Close Dispute"
          onClick={this.closeDispute}
          variant="outlined"
          style={{ color: "green" }}
        />
      </div>
    )
  }

  renderStatus = () => {
    let { row } = this.state
    let { dispute_status_enum } = this.props
    if (row == null || row.dispute_status == 1) return null
    return (
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ display: "inline-block" }}
      >
        - {dispute_status_enum[row.dispute_status]}
      </Typography>
    )
  }

  renderContent = () => {
    let { onEdit, row } = this.state
    let { onSuccess, service_id, dispute_ref_id, astrologer_id } = this.props
    return (
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          {row == null && !onEdit && (
            <Typography align="center">
              If you are not satisfied with the consulation
              <Button onClick={() => this.setState({ onEdit: true })}>
                click to raise a dispute
              </Button>
            </Typography>
          )}
          {row != null && !onEdit && this.renderBlock(row)}
          {onEdit && (
            <DisputeForm
              onSuccess={() => {
                this.fetchRow()
                this.setState({ onEdit: false, result: null })
                onSuccess && onSuccess()
              }}
              service_id={service_id}
              dispute_ref_id={dispute_ref_id}
              astrologer_id={astrologer_id}
            />
          )}
        </CardContent>
      </Card>
    )
  }

  render() {
    let { isLoading, row } = this.state
    let { dispute_status } = this.props
    if (dispute_status <= 0 || dispute_status == 6) return null
    if (isLoading) return null
    return (
      <div>
        <Typography
          variant="subheading"
          color="error"
          style={{ margin: 10, marginTop: 20 }}
        >
          DISPUTE {this.renderStatus()}
        </Typography>
        {this.renderContent()}
        {row != null && this.renderChat()}
        {row != null && this.renderActions()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  let dispute_titles = enums.rows.dispute_titles.vt
  let dispute_status_enum = enums.rows.dispute_status.vt
  return { dispute_titles, dispute_status_enum }
}
export default connect(mapStateToProps)(withStyles(styles)(DisputeRoot))
