import React, { useState, useEffect } from "react"
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { apiv2Instance } from "../../../utils"

const styles = () => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: "30px",
  },
  table: {
    minWidth: 200,
  },
  noData: {
    marginTop: "30px",
  },
})

function SimpleTable(props) {
  const { classes } = props
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async function () {
      try {
        const response = await apiv2Instance.get(
          "/admin/analytics/banner/click"
        )
        setData(response.data.data)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return (
      <div className={classes.noData}>
        <CircularProgress />
      </div>
    )
  }

  if ((!data || data.length === 0) && loading === false) {
    return (
      <div className={classes.noData}>
        <Typography variant="h5">No data available</Typography>
      </div>
    )
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Banner Name</TableCell>
            <TableCell>Clicks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell scope="row">{row.bannerName}</TableCell>
              <TableCell>{row.clickCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(SimpleTable)
