import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap.css"
import React, { Component } from "react"
import Tooltip from "rc-tooltip"
import Slider from "rc-slider"
import Typography from "@material-ui/core/Typography"

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)
const Handle = Slider.Handle

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  )
}

const wrapperStyle = { width: "90%", margin: 5 }

class ServiceSlider extends Component {
  state = {
    value: 0,
  }

  componentDidMount = () => this.setState({ value: this.props.defaultValue })

  render() {
    let { min, max, defaultValue, onChange } = this.props
    let marks = {
      [min]: min,
      //[parseInt( max/4 )] : parseInt( max/4 ),
      //[parseInt( max/3 )] : parseInt( max/3 ),
      [parseInt(max / 2)]: parseInt(max / 2),
      [max]: max,
    }

    if (!max || !min) return null

    return (
      <div style={wrapperStyle}>
        <Typography align="center" color="primary" variant="h3">
          {this.state.value}
        </Typography>
        <Slider
          min={min}
          max={max}
          defaultValue={parseInt(defaultValue)}
          handle={handle}
          marks={marks}
          tipFormatter={(value) => `${value}% INR`}
          onChange={(value) => {
            this.setState({ value })
          }}
          onAfterChange={onChange}
        />
      </div>
    )
  }
}

export default ServiceSlider
