import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { instance } from "../../../utils/functions"
import { Progress, ConfirmationDialog } from "../../../utils/components"
import { pushMsg } from "../../../navigation/actions"
const API = `phonecall/phonecall_admin/cancel`

class PhonecallCancelButton extends Component {
  state = {
    confimrationOpen: false,
    isLoading: false,
  }

  _setState = (newState) => this.setState(newState)

  handleCancellation = () => {
    let { dispatch, fetchRows } = this.props
    let { phonecall_id } = this.props.row
    this.setState({ isLoading: true })
    instance.post(API, { phonecall_id }).then((result) => {
      this.setState({ isLoading: false })
      dispatch(pushMsg(result.msg))
      fetchRows()
    })
  }

  render() {
    if (this.state.isLoading) return <Progress />
    let { row, disputeStatusMsg } = this.props
    let { dispute_status } = row
    dispute_status = parseInt(dispute_status)
    if (dispute_status == 0) return null
    let canReverse = dispute_status < 3 || dispute_status > 4
    return (
      <React.Fragment>
        {canReverse && (
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => this.setState({ confimrationOpen: true })}
          >
            REVERSE PAYMENT
          </Button>
        )}
        {!canReverse && (
          <Button color="secondary" variant="text">
            {disputeStatusMsg[dispute_status].toUpperCase()}
          </Button>
        )}
        <ConfirmationDialog
          confimrationOpen={this.state.confimrationOpen}
          _setState={this._setState}
          title={"Are you sure ?"}
          description={"This will cancel booking and amount will be refunded!"}
          onSuccess={this.handleCancellation}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let disputeStatusMsg = enums.rows.dispute_status.vt
  return { disputeStatusMsg }
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallCancelButton)
)
