import { applyMiddleware } from "redux"
import { routerMiddleware } from "connected-react-router"
import { createLogger } from "redux-logger"
import promise from "redux-promise-middleware"
import thunk from "redux-thunk"
import REDUX_PROMISE_AXIOS from "redux-promise-axios"
import createHistory from "history/createBrowserHistory"

import { history } from "./"

export default applyMiddleware(
  createLogger(),
  routerMiddleware(history),
  thunk,
  promise(),
  REDUX_PROMISE_AXIOS
)
