import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

class EmptyCard extends Component {
  renderContent = () => {
    let { classes, msg } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4}>
          <Icon className={classes.icon}>storage</Icon>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subheading" color="textSecondary">
            {msg || `List empty!`}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    let { card } = this.props
    if (card)
      return (
        <Card>
          <CardContent>{this.renderContent()}</CardContent>
        </Card>
      )
    return this.renderContent()
  }
}

const styles = (theme) => ({
  icon: {
    fontSize: 50,
    color: "#ccc",
  },
})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(EmptyCard))
