import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Icon from "@material-ui/core/Icon"

import fields from "../fields"
import ReportRow from "../row"
import ReportRowForm from "../form"
import { instance } from "../../../../utils"
import { guid } from "../../../../utils/functions"
import { pushMsg } from "../../../../navigation/actions"
import { FormResult, FormButton } from "../../../../utils/form"

import SolutionLifetimeAgeForm from "./ageForm"

const API_SUBMIT = `report/update`
const row_fields = fields["4"]

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

class ReportSolutionLifetime extends Component {
  state = {
    isLoading: true,
    rows: this.props.rows || [],
    result: null,
    report: {},
    onEdit: !this.props.solutionAdded,
    tabVal: null,
    showAgeForm: !this.props.solutionAdded,
    submitIsLoading: false,
  }

  componentWillMount = () => {
    let { rows } = this.props
    if (rows && rows.length) this.setState({ tabVal: rows[0].lifetime_id })
  }

  pushRow = (lifetime_agefrom, lifetime_ageto, lifetime_id = null) => {
    let { requirement_id, dispatch } = this.props
    if (parseInt(lifetime_agefrom) == parseInt(lifetime_ageto)) {
      dispatch(pushMsg("Please check your entries!", "error"))
      return
    }
    let { rows } = this.state
    let row
    if (lifetime_id == null) {
      lifetime_id = guid()
      row = { lifetime_id, requirement_id, lifetime_agefrom, lifetime_ageto }
    } else {
      row = rows.find((x) => x.lifetime_id === lifetime_id)
      row = Object.assign({ lifetime_agefrom, lifetime_ageto }, row)
    }
    rows.push(row)
    this.setState({ rows, showAgeForm: false, tabVal: lifetime_id })
  }

  handleSubmit = () => {
    let { rows } = this.state
    let { requirement_id, fetchRow } = this.props
    let data = { requirement_id, rows: {} }
    rows.map((x) => (data.rows[x.lifetime_id] = x))
    this.setState({ submitIsLoading: true, result: null })
    instance({
      url: API_SUBMIT,
      method: "post",
      data,
    }).then((result) => {
      let { res, msg, row } = result
      if (res == 2) {
        this.setState({ result })
      } else fetchRow()
      this.setState({ submitIsLoading: false })
    })
  }

  renderTabs = () => {
    let { rows } = this.state
    return (
      <Tabs
        value={this.state.tabVal}
        onChange={(e, value) => this.setState({ tabVal: value })}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        fullWidth
      >
        {rows.map((item) => (
          <Tab
            value={item.lifetime_id}
            key={item.lifetime_id}
            label={`${item.lifetime_agefrom} - ${item.lifetime_ageto}`}
          />
        ))}
      </Tabs>
    )
  }

  renderTabContainers = () => {
    let { rows, tabVal, onEdit } = this.state
    return (
      <React.Fragment>
        {rows.map(
          (item, index) =>
            tabVal === item.lifetime_id && (
              <React.Fragment key={`${item.lifetime_agefrom}-container`}>
                {Object.keys(row_fields).map((x) => {
                  return onEdit ? (
                    <ReportRowForm
                      key={x}
                      title={row_fields[x]}
                      text={item[x] || null}
                      onChange={(e) => {
                        let { rows } = this.state
                        rows[index][x] = e.target.value
                        this.setState({ rows })
                      }}
                    />
                  ) : (
                    <ReportRow
                      key={x}
                      title={row_fields[x]}
                      text={item[x] || null}
                    />
                  )
                })}
              </React.Fragment>
            )
        )}
      </React.Fragment>
    )
  }

  renderActions = () => {
    let { result, submitIsLoading, onEdit } = this.state
    return (
      <React.Fragment>
        <FormResult result={result} />
        {onEdit && (
          <FormButton
            color="primary"
            fullWidth
            variant="contained"
            loading={submitIsLoading}
            onClick={this.handleSubmit}
          />
        )}
        {!onEdit && (
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => this.setState({ onEdit: true })}
          >
            <Icon>edit</Icon> Edit
          </Button>
        )}
      </React.Fragment>
    )
  }

  render() {
    let { rows, showAgeForm } = this.state

    return (
      <div>
        {showAgeForm && <SolutionLifetimeAgeForm pushRow={this.pushRow} />}
        {!showAgeForm && (
          <Button
            onClick={() => this.setState({ showAgeForm: true })}
            fullWidth
            color="primary"
          >
            {" "}
            ADD TAB{" "}
          </Button>
        )}
        {!rows.length && (
          <Typography variant="caption" color="primary">
            You can add multiple age groups to give report on different life
            span.
          </Typography>
        )}
        {this.renderTabs()}
        {this.renderTabContainers()}
        {this.renderActions()}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(ReportSolutionLifetime)
)
