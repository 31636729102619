import React from "react"
import Tabs from "./components/Tabs"

export default function LiveChat() {
  return (
    <div>
      <Tabs />
    </div>
  )
}
