import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

import { timeAgo } from "../../../utils/functions"
import { CardWrapper } from "../../../utils/hoc"
import { instance } from "../../../utils"
import { FormResult, FormButton } from "../../../utils/form"
import { Progress } from "../../../utils/components"
import fields from "./fields"
import ReportRow from "./row"
import ReportRowForm from "./form"
import SolutionMatchScore from "./matchScore"
import ReportSolutionLifetime from "./lifetime"
import ReportSolutionYear from "./year"

const API = `report/get_report`
const API_SUBMIT = `report/update`

class ReportSolution extends Component {
  state = {
    isLoading: true,
    row: null,
    result: null,
    row_fields: {},
    submitIsLoading: false,
    report: {},
    onEdit: false,
  }

  componentWillMount = () => {
    let { requirement } = this.props
    let { mr_service_id } = requirement
    let row_fields = fields[mr_service_id]
    this.setState({ row_fields }, this.fetchRow)
  }

  fetchRow = () => {
    let { requirement_id } = this.props
    let { row_fields } = this.state
    this.setState({ isLoading: true, result: null })
    instance({
      url: API,
      data: { requirement_id },
      method: "post",
    }).then((result) => {
      let { res, msg, row, rows } = result
      if (res == 2) {
        this.setState({ result })
      }
      if (res == 1) {
        if (rows !== null && rows !== undefined) row = rows[0] || {}
        let report = {}
        if (row_fields) {
          Object.keys(row_fields).map((x) => (report[x] = row[x] || ""))
        }
        this.setState({ row, rows, report })
      }
      this.setState({ isLoading: false })
    })
  }

  handleSubmit = () => {
    let { report } = this.state
    let { requirement_id } = this.props
    report.requirement_id = requirement_id
    this.setState({ submitIsLoading: true, result: null })
    instance({
      url: API_SUBMIT,
      method: "post",
      data: report,
    }).then((result) => {
      let { res, msg, row } = result
      if (res == 2) {
        this.setState({ result })
      } else this.setState({ onEdit: false })
      this.setState({ submitIsLoading: false })
      this.fetchRow()
    })
  }

  renderActions = () => {
    let { result, submitIsLoading, onEdit } = this.state
    return (
      <React.Fragment>
        {onEdit && (
          <FormButton
            color="primary"
            fullWidth
            variant="contained"
            loading={submitIsLoading}
            onClick={this.handleSubmit}
          />
        )}
        {!onEdit && (
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => this.setState({ onEdit: true })}
          >
            <Icon>edit</Icon> Edit
          </Button>
        )}
      </React.Fragment>
    )
  }

  renderContent = () => {
    let { requirement, solutionAdded, requirement_id } = this.props
    let { row, rows, row_fields, onEdit, report } = this.state
    let { mr_service_id } = requirement

    if (mr_service_id == 3)
      return (
        <ReportSolutionYear
          row={row}
          requirement_id={requirement_id}
          solutionAdded={solutionAdded}
          fetchRow={this.fetchRow}
        />
      )

    if (mr_service_id == 4)
      return (
        <ReportSolutionLifetime
          rows={rows}
          requirement_id={requirement_id}
          solutionAdded={solutionAdded}
          fetchRow={this.fetchRow}
        />
      )

    let rowsList = []
    for (let [k, v] of Object.entries(row_fields)) {
      if (k === "horoscope_match_score")
        rowsList.push(
          <SolutionMatchScore
            onEdit={onEdit}
            key={k}
            title={v}
            value={row ? report[k] : null}
            onChange={(e, value) => {
              let { report } = this.state
              report[k] = value
              this.setState({ report })
            }}
          />
        )
      else
        rowsList.push(
          !onEdit ? (
            <ReportRow key={k} title={v} text={row ? row[k] : null} />
          ) : (
            <ReportRowForm
              key={k}
              title={v}
              text={row ? row[k] : null}
              onChange={(e) => {
                let { report } = this.state
                report[k] = e.target.value
                this.setState({ report })
              }}
            />
          )
        )
    }
    return (
      <div>
        {rowsList}
        {requirement.report_join_date && (
          <Typography align="right" variant="body1" color="secondary">
            submitted {timeAgo(requirement.report_join_date)}
          </Typography>
        )}
        {this.renderActions()}
      </div>
    )
  }

  render() {
    let { result, row, isLoading, onEdit } = this.state
    if (isLoading) return <Progress />

    return (
      <div>
        {this.renderContent()}
        <FormResult result={result} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = (
  { requirement: requirementList, enums },
  { requirement }
) => {
  let requirement_id = requirement.requirement_id
  let solutionAdded = requirement.requirement_status > 5
  return { requirement, solutionAdded, requirement_id }
}
export default connect(mapStateToProps)(
  withStyles(styles)(CardWrapper(ReportSolution))
)
