import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { PersistGate } from "redux-persist/integration/react"

import theme from "./theme"
import routes from "./routes"
import Layout from "./layouts"
import configureStore, { history } from "../store"

let { persistor, store } = configureStore()

const Root = ({ store }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <div>
            <Switch>
              {routes.map(({ exact, path, component, ...rest }, idx) => (
                <Layout
                  key={idx}
                  exact={exact}
                  path={path}
                  component={component}
                  {...rest}
                />
              ))}
            </Switch>
          </div>
        </ConnectedRouter>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export { history }

export default Root
