import React from "react"
import { withStyles, Paper, InputBase, IconButton } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { apiv2Instance } from "../../../utils"

const styles = {
  root: {
    padding: "3px 5px",
    display: "flex",
    alignItems: "center",
    minWidth: "250px",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}

function Search(props) {
  const { classes, query, setQuery, setData, setNoData, setLoading } = props

  async function handleSearch(e) {
    try {
      e.preventDefault()
      setLoading(true)
      setNoData(false)
      setData(null)
      const response = await apiv2Instance.get(`/admin/chat/search?q=${query}`)
      setData(response.data.rooms)
    } catch (e) {
      if (e.response.status === 404) {
        setNoData(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper
      className={classes.root}
      elevation={1}
      component="form"
      onSubmit={handleSearch}
    >
      <InputBase
        className={classes.input}
        placeholder="Search Astrologer by name"
        onChange={(e) => setQuery(e.target.value)}
        required
        autoFocus
      />
      <IconButton className={classes.iconButton} type="submit">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default withStyles(styles)(Search)
