function isObject(value) {
  return value === Object(value)
}

function isArray(value) {
  return Array.isArray(value)
}

function isFile(value) {
  return value instanceof File
}

export function objectToFormData(obj, fd, pre) {
  fd = fd || new FormData()

  Object.keys(obj).forEach(function (prop) {
    var key = pre ? pre + "[" + prop + "]" : prop

    if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
      objectToFormData(obj[prop], fd, key)
    } else if (isArray(obj[prop])) {
      obj[prop].forEach(function (value) {
        var arrayKey = key + "[]"
        if (isObject(value) && !isFile(value)) {
          objectToFormData(value, fd, arrayKey)
        } else {
          value = value === undefined || value === null ? "" : value
          fd.append(arrayKey, value)
        }
      })
    } else {
      obj[prop] = obj[prop] === undefined || obj[prop] === null ? "" : obj[prop]
      fd.append(key, obj[prop])
    }
  })

  return fd
}
