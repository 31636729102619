import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { instance } from "../../../../utils"
import { FormResult, FormButton } from "../../../../utils/form"
import { pushMsg } from "../../../../navigation/actions"
const API = "astrologer/astrologer_custom_service/update"

class CustomForm extends Component {
  state = {
    isLoading: false,
    result: null,
    acs_id: 0,
    acs_name: "",
    acs_description: "",
    acs_service_rate: "",
    acs_bd_min: 1,
    acs_bd_max: 1,
  }

  componentWillMount = () => {
    let newData = Object.assign({}, this.state, this.props.row)
    this.setState(newData)
  }

  handleChange =
    (name, isInt = false) =>
    (event) => {
      let val = event.target.value
      if (isInt) val = val == parseInt(val, 10) ? parseInt(val) : 0
      this.setState({
        [name]: val,
      })
    }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let {
      acs_id,
      acs_name,
      acs_description,
      acs_service_rate,
      acs_bd_min,
      acs_bd_max,
    } = this.state
    let data = {
      acs_id,
      acs_name,
      acs_description,
      acs_service_rate,
      acs_bd_min,
      acs_bd_max,
    }
    let { dispatch, row, _setState, refreshData } = this.props
    data.user_id = this.props.row.user_id
    instance.post(API, data).then((result) => {
      this.setState({ isLoading: false })
      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      _setState({ modalOpen: false })
      dispatch(pushMsg(msg, "success"))
      refreshData()
    })
  }

  render() {
    let { classes } = this.props
    let { isLoading, result } = this.state
    console.log("row @ form ", this.props.row)
    return (
      <div>
        <TextField
          fullWidth
          label="Title"
          value={this.state.acs_name}
          onChange={this.handleChange("acs_name")}
          className={classes.input}
        />
        <TextField
          fullWidth
          multiline
          label="Decsription"
          value={this.state.acs_description}
          onChange={this.handleChange("acs_description")}
          className={classes.input}
        />
        <TextField
          fullWidth
          label="Service Rate"
          value={this.state.acs_service_rate}
          onChange={this.handleChange("acs_service_rate", true)}
          className={classes.input}
        />
        <TextField
          label="Minimum birth details required"
          value={this.state.acs_bd_min}
          onChange={this.handleChange("acs_bd_min", true)}
          className={classes.bdInput}
        />
        <TextField
          label="Maximum birth details accepted"
          value={this.state.acs_bd_max}
          onChange={this.handleChange("acs_bd_max", true)}
          className={classes.bdInput}
        />
        <FormResult result={result} />
        <FormButton
          onClick={this.handleSubmit}
          loading={isLoading}
          variant="outlined"
          fullWidth
          color="primary"
        />
      </div>
    )
  }
}

const styles = (theme) => ({
  input: {
    marginTop: 30,
  },
  bdInput: {
    marginTop: 30,
    width: "50%",
  },
})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(CustomForm))
