import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Dropzone from "react-dropzone"
import axios from "axios"

import { api_base } from "../../../config"
import { pushMsg } from "../../../navigation/actions"
import { Progress } from "../"
var instance = axios.create()
instance.defaults.timeout = 25000
instance.defaults.withCredentials = true

class ProfilePicUploader extends Component {
  state = {
    files: [],
    rejected: [],
    isLoading: false,
  }

  onDrop = (files, rejected) => {
    if (files.length) this.handleUpload(files)
    if (rejected.length) this.props.dispatch(pushMsg("File rejected!"))
  }

  handleUpload = (files) => {
    this.setState({ isLoading: true })
    let { dispatch, onSuccess, api, identity, value } = this.props
    var formData = new FormData()
    formData.append([identity], files[0])
    if (value) formData.append(`${identity}_last`, value)
    instance
      .post(`${api_base}${api}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        this.setState({ isLoading: false })
        dispatch(pushMsg(data.msg, data.res == 1 ? "success" : "info"))
        if (data.res == 1) onSuccess && onSuccess()
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        dispatch(pushMsg("Error", "warn"))
      })
  }

  renderUploadButton = () => (
    <Dropzone onDrop={this.onDrop} accept="image/jpeg, image/png">
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={classNames("dropzone", {
              "dropzone--isActive": isDragActive,
            })}
          >
            <input {...getInputProps()} type="file" />
            {this.state.isLoading ? <Progress /> : this.props.children}
          </div>
        )
      }}
    </Dropzone>
  )

  render() {
    return <React.Fragment>{this.renderUploadButton()}</React.Fragment>
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ProfilePicUploader))
