import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

import { timeAgo } from "../../../utils/functions"
import { CardWrapper } from "../../../utils/hoc"
import { instance } from "../../../utils"
import { FormResult, FormButton } from "../../../utils/form"
import { Progress } from "../../../utils/components"
import ReportRow from "../../report/solution/row"
import ReportRowForm from "../../report/solution/form"

const SAVE_API = `astrologer/astrologer_test_booking/update_solution`

class TestBookingSolution extends Component {
  state = {
    isLoading: false,
    row: this.props.row,
    result: null,
    row_fields: {
      myreport_present: "Present",
      myreport_cause: "Cause",
      myreport_solution: "Solution",
      myreport_futue: "Future",
    },
    submitIsLoading: false,
    report: this.props.row || {},
    onEdit: !this.props.solutionAdded,
  }

  handleSubmit = () => {
    let { report } = this.state
    let { row, solutionAdded, fetchRow } = this.props
    report.requirement_id = row.requirement_id
    report.solution_added = solutionAdded ? 1 : 0
    this.setState({ submitIsLoading: true, result: null })
    instance({
      url: SAVE_API,
      method: "post",
      data: report,
    }).then((result) => {
      let { res, msg, row } = result
      if (res == 2) {
        this.setState({ result })
      } else {
        this.setState({ onEdit: false })
        fetchRow()
      }
      this.setState({ submitIsLoading: false })
    })
  }

  renderActions = () => {
    let { result, submitIsLoading, onEdit } = this.state
    return (
      <React.Fragment>
        {onEdit && (
          <FormButton
            color="primary"
            fullWidth
            variant="contained"
            loading={submitIsLoading}
            onClick={this.handleSubmit}
          />
        )}
        {!onEdit && (
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => this.setState({ onEdit: true })}
          >
            <Icon>edit</Icon> Edit
          </Button>
        )}
      </React.Fragment>
    )
  }

  renderContent = () => {
    let { requirement, solutionAdded, requirement_id } = this.props
    let { row, rows, row_fields, onEdit, report } = this.state
    let { mr_service_id } = requirement

    let rowsList = []
    for (let [k, v] of Object.entries(row_fields)) {
      rowsList.push(<ReportRow key={k} title={v} text={row ? row[k] : null} />)
    }
    return (
      <div>
        {rowsList}
        {requirement.report_join_date && (
          <Typography align="right" variant="body1" color="secondary">
            submitted {timeAgo(requirement.report_join_date)}
          </Typography>
        )}
        {this.renderActions()}
      </div>
    )
  }

  render() {
    let { result, row, isLoading, onEdit } = this.state
    if (isLoading) return <Progress />

    return (
      <div>
        {this.renderContent()}
        <FormResult result={result} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement: requirementList, enums }, { row }) => {
  let requirement = row
  let solutionAdded = requirement.requirement_status > 5
  return { requirement, solutionAdded }
}
export default connect(mapStateToProps)(
  withStyles(styles)(CardWrapper(TestBookingSolution))
)
