import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { withRouter } from "react-router-dom"

import UserGroupFetcher from "../../users/groupFetcher"
import {
  getUploadImge,
  convertMysqlDateTime,
  createUrl,
} from "../../../utils/functions"
import { ConfirmationDialog, ModalComponent } from "../../../utils/components"
import ReportInteraction from "../interaction"
import ReportCancelButton from "./cancelButton"
class ReportBookingListRow extends Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  showBooking = () => this.setState({ modalOpen: true })

  fetchRows = () => this.props.fetchRows()

  renderContent = () => {
    let {
      classes,
      row,
      report_booking_status,
      my_report_services,
      report_services,
      users,
    } = this.props
    let astrologer = users.rows[row.astrologer_id]
    let user = users.rows[row.user_id]
    let isActive = [2, 3, 4, 5, 6, 7, 8].includes(
      parseInt(row.requirement_status)
    )
    let isPending = parseInt(row.requirement_status) == 1
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            src={getUploadImge(user ? user.image : null, "user/avatar")}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <UserGroupFetcher visualise ids={[row.user_id]}>
            {!user ? (
              <Typography>User loading...</Typography>
            ) : (
              <Typography className={classes.name} style={{ color: "blue" }}>
                {`${user.first_name} ${user.last_name}`}
              </Typography>
            )}
          </UserGroupFetcher>
          <UserGroupFetcher ids={[row.astrologer_id]}>
            {!astrologer ? (
              <Typography>User loading...</Typography>
            ) : (
              <Typography className={classes.name}>
                {`${astrologer.first_name} ${astrologer.last_name}`}
              </Typography>
            )}
          </UserGroupFetcher>
          <Typography variant="caption" color="textSecondary">
            #{row.requirement_id}
          </Typography>
          <Typography color="primary" variant="subheading">
            {report_services[row.mr_service_id]}
            {parseInt(row.my_report_id) > 0 &&
              ` - ${my_report_services[row.my_report_id]} `}
          </Typography>
          {row.requirement_join_date && (
            <Typography color="textSecondary" variant="caption">
              {convertMysqlDateTime(row.requirement_join_date, true)}
            </Typography>
          )}
          <Typography
            variant="body1"
            color={isPending ? "error" : isActive ? "secondary" : "textPrimary"}
          >
            {report_booking_status[row.requirement_status]} -{" "}
            {row.requirement_status} - ({" "}
            {row.report_confirm_date
              ? convertMysqlDateTime(row.report_confirm_date, true)
              : `in progress`}{" "}
            )
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    let { classes, row } = this.props

    return (
      <React.Fragment>
        <Card style={{ marginTop: 5, marginBottom: 5 }}>
          <CardContent>{this.renderContent()}</CardContent>
          <CardActions
            disableActionSpacing
            style={{ justifyContent: "flex-end", paddingTop: 0 }}
          >
            {row.requirement_status > 1 && row.requirement_status <= 9 && (
              <ReportCancelButton fetchRows={this.fetchRows} row={row} />
            )}
            <Button
              className={classes.actionButton}
              color="primary"
              size="small"
              onClick={this.showBooking}
            >
              View <Icon>chevron_right</Icon>
            </Button>
          </CardActions>
        </Card>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="ReportBooking"
          content={() => <ReportInteraction requirement={row} />}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  avatar: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
    maxWidth: 140,
  },
  leftGrid: {
    padding: 10,
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  name: {
    color: "#31708f",
    fontSize: 18,
  },
  actionButton: {
    padding: "0 20px",
  },
})

const mapStateToProps = ({ users, enums, auth }, { row }) => {
  let { user_id } = row
  let report_booking_status = enums.rows.report_booking_status.vt
  let my_report_services = enums.rows.my_report_services.vt
  let report_services = enums.rows.report_services.vt
  return {
    report_booking_status,
    my_report_services,
    report_services,
    users,
  }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ReportBookingListRow))
)
