import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import TextField from "@material-ui/core/TextField"

import StarRatingComponent from "react-star-rating-component"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"
import { FormResult, FormButton } from "../../../utils/form"

const API = `review/update`

class ReviewForm extends Component {
  state = {
    isLoading: false,
    review_id: 0,
    review_rating: 0,
    review_text: "",
    first_save: true,
  }

  componentWillMount = () => {
    let { row } = this.props
    if (row == null) return
    let { review_id, review_text, review_rating } = row
    review_rating = parseInt(review_rating)
    this.setState({ review_id, review_rating, review_text, first_save: false })
  }

  handleSubmit = () => {
    let { service_id, reference_id, astrologer_id, onSuccess } = this.props
    let { review_rating, review_text, first_save, review_id } = this.state
    this.setState({ isLoading: true, result: null })
    instance({
      method: "post",
      url: API,
      data: {
        review_id,
        service_id,
        reference_id,
        astrologer_id,
        review_rating,
        review_text,
      },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        if (!first_save) {
          onSuccess && onSuccess(result)
        }
        this.setState({ first_save: false, review_id: result.review_id })
        this.props.dispatch(pushMsg(`Success !`, "success"))
      }
    })
  }

  handleStarChange = (nextValue, prevValue, name) => {
    this.setState({ [name]: nextValue }, () => {
      if (prevValue == 0) this.handleSubmit()
    })
  }
  renderStar = () => (
    <div style={{ textAlign: "center" }}>
      <StarRatingComponent
        name="review_rating"
        value={this.state.review_rating}
        onStarClick={this.handleStarChange}
        renderStarIcon={() => <Icon fontSize="large">star</Icon>}
        editing={true}
      />
    </div>
  )

  render() {
    let { result, review_text, isLoading, first_save } = this.state
    return (
      <div>
        {this.renderStar()}
        <FormResult result={result} />
        {!first_save && (
          <TextField
            label="Please give a testimonial for this astrologer..."
            value={review_text}
            onChange={(event) =>
              this.setState({ review_text: event.target.value })
            }
            multiline
            fullWidth
            rows="3"
          />
        )}
        {review_text !== "" && (
          <FormButton
            loading={isLoading}
            variant="outlined"
            fullWidth
            color="secondary"
            label="Submit"
            onClick={this.handleSubmit}
          />
        )}
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReviewForm))
