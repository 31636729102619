import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import { FormResult, FormButton } from "../../../../utils/form"
import { PicUploader } from "../../../../utils/components/misc"
import { getUploadImge } from "../../../../utils/functions"

import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API_FETCH = `astrologer/astrologer_bank/get_row`
const API_UPDATE = `astrologer/astrologer_bank/update`

const fields = {
  bank_account_name: "Account holder Name",
  bank_account_number: "Account Number",
  bank_name: "Bank name",
  bank_branch: "Branch",
  bank_ifsc: "IFSC Code",
}

class ProfileBank extends Component {
  state = {
    isLoading: false,
    row: {},
    result: null,
  }

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    this.setState({ isLoading: true })
    let { user_id } = this.props.row
    instance.post(API_FETCH, { user_id }).then((result) => {
      this.setState({ row: result.row || {}, isLoading: false })
    })
  }

  handleChange = (name) => (event) =>
    this.changeUserState(name, event.target.value)

  changeUserState = (key, val) => {
    let row = Object.assign({}, this.state.row)
    row[key] = val
    this.setState({ row })
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { row } = this.state
    let { dispatch, user_id } = this.props
    row.user_id = user_id
    if(!row.user_id){
      row.user_id = this.props.row.user_id
    }
    instance.post(API_UPDATE, row).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      window.scrollTo(0, 0)
      dispatch(pushMsg(msg, "success"))
      this.fetchRow()
    })
  }

  renderTextField = (name, label) => {
    let { classes } = this.props
    let { row } = this.state
    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={row[name] != null ? row[name] : ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  renderImageUpload = () => {
    let { row } = this.state
    if (!row.bank_id) return null
    return (
      <PicUploader
        api={`astrologer/astrologer_bank/update_image`}
        identity="bank_image"
        value={row.bank_image}
        onSuccess={() => this.fetchRow()}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            background: "#f6ebf9",
            padding: 20,
          }}
        >
          <img
            src={getUploadImge(row.bank_image, "astrologer/bank")}
            style={{ width: "100%", maxWidth: 400, margin: "0 auto" }}
          />
        </div>
        <Typography color="secondary" variant="subheading" align="center">
          {row.bank_image
            ? "Change cancelled check image"
            : " Add your cancelled check image "}
        </Typography>
      </PicUploader>
    )
  }

  renderContent = () => {
    let { row, dispatch } = this.props
    let { isLoading, result } = this.state

    return (
      <Card>
        <CardContent>
          {Object.keys(fields).map((x) => this.renderTextField(x, fields[x]))}
          <FormResult result={result} />
          <FormButton
            loading={isLoading}
            label="Save"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ maxWidth: 600, margin: 0 }}
          />
        </CardContent>
      </Card>
    )
  }

  render() {
    return this.renderContent()
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums, auth }, { user_id }) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileBank))
