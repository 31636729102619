import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import ReportRate from "./rate"
import ReportStatusSwitch from "./statusSwitch"

class ReportRow extends Component {
  render() {
    let { row } = this.props
    return (
      <div>
        <ReportStatusSwitch row={row} />
        <ReportRate row={row} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportRow))
