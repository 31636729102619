import React from "react"
import { Typography } from "@material-ui/core"

export default function NoData() {
  return (
    <div style={{ textAlign: "center", marginTop: "60px" }}>
      <Typography variant="h5">No chats found.</Typography>
    </div>
  )
}
