import React, { Component } from "react"
import Root from "./navigation/root"
import configureStore from "./store"

let { store } = configureStore()

class App extends Component {
  render() {
    return <Root store={store} />
  }
}

export default App
