import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import { getUploadImge, createUrl, _toFixed } from "../../../utils/functions"

class AstrologerMainCard extends Component {
  showProfile = () => {
    let { row, history } = this.props
    return
    history.push(createUrl("/astrologer", { id: row.id }))
  }

  renderRating = () => {
    let reviewCount = (
      <div className={this.props.classes.ratingCount}>
        {this.props.row.rating_count} Reviews
      </div>
    )
    if (this.props.row.isNew)
      reviewCount = <div className={this.props.classes.ratingNew}>New</div>
    return (
      <div>
        <div className={this.props.classes.rating}>
          {parseFloat(this.props.row.rating).toFixed(1)}
          <Icon className={this.props.classes.ratingStar}>star</Icon>
        </div>
        {reviewCount}
      </div>
    )
  }

  render() {
    const {
      classes,
      theme,
      row,
      professions,
      languages,
      id,
      isFavorite,
      dispatch,
      rightGridExtra,
    } = this.props
    return (
      <React.Fragment>
        <Grid container justify="center" className={classes.mainGrid}>
          <Grid item xs={3} className={classes.leftGrid}>
            <Avatar
              src={getUploadImge(row.image, "user/avatar")}
              classes={{ img: classes.avatarImg }}
              className={classes.avatar}
              onClick={this.showProfile}
            />
            {this.renderRating()}
            <IconButton
              color="secondary"
              onClick={() =>
                (window.location = `https://www.api.monkvyasa.com/user/user_admin/force_login/${row.id}`)
              }
            >
              <Icon>lock_open</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={9} className={classes.rightGrid}>
            <div className={classes.content}>
              <Typography className={classes.name} onClick={this.showProfile}>
                {`${row.first_name} ${row.last_name}`} - #{row.id}
              </Typography>
              <Typography
                className={classes.name}
                style={{ color: "blue" }}
                onClick={this.showProfile}
              >
                {`${row.phone}`}
              </Typography>
              <Typography
                className={classes.name}
                style={{ color: "red" }}
                onClick={this.showProfile}
              >
                {`${_toFixed(row.wallet)} INR`}
              </Typography>
              <Typography
                className={classes.city}
                color="textSecondary"
                gutterBottom
              >
                <Icon
                  style={{ fontSize: 12, marginRight: 5, color: "#777171" }}
                >
                  location_on
                </Icon>
                {row.city}
              </Typography>
              <Typography>
                {row.professions.split(",").map((item) => (
                  <span key={item} className={classes.profession}>
                    {professions[item] ? professions[item] : ""}{" "}
                  </span>
                ))}
              </Typography>
              <Typography>
                {row.languages.split(",").map((item) => (
                  <span key={item} className={classes.language}>
                    {languages[item] ? languages[item] : ""}{" "}
                  </span>
                ))}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {rightGridExtra && rightGridExtra()}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  mainGrid: {
    minHeight: 130,
  },
  leftGrid: {
    alignSelf: "center",
    textAlign: "center",
  },
  rightGrid: {
    alignSelf: "flex-start",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingTop: 20,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  avatar: {
    margin: "0 auto",
    marginBottom: 4,
    marginTop: 4,
    width: 60,
    height: 60,
  },
  avatarImg: {
    //width : 200
  },
  button: {
    margin: theme.spacing.unit,
    width: "90%",
    borderRadius: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  city: {
    fontSize: 13,
    color: "#000",
  },
  name: {
    color: "#31708f",
    fontSize: 15,
  },
  profession: {
    marginRight: 10,
    color: "#0000008a",
    fontSize: 12,
  },
  language: {
    marginRight: 10,
    color: "#274e61",
    fontSize: 13,
  },
  actions: {
    textAlign: "center",
    margin: 0,
  },
  rating: {
    backgroundColor: "#37be5f",
    color: "#f7f7f7",
    fontSize: 12,
    display: "inline-block",
    margin: 0,
    padding: "3px 7px",
  },
  ratingCount: {
    color: "#999",
    fontSize: 11,
    textAlign: "center",
  },
  ratingStar: {
    fontSize: 14,
    marginLeft: 2,
  },
  ratingNew: {
    color: "#f52020",
    fontSize: 11,
    textAlign: "center",
    padding: 2,
    margin: "0 auto",
    border: "1px solid #ccc",
    marginTop: 2,
    background: "#dcb6b6",
    width: 60,
  },
  favButton: {
    float: "right",
    display: "inline-block",
  },
})

const mapStateToProps = ({ astrologers, enums }, { row }) => {
  row.isNew = row.new
  return {
    row,
    languages: enums.rows.languages.vt,
    professions: enums.rows.professions.vt,
  }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(AstrologerMainCard))
)
