import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import {
  FormResult,
  FormButton,
  FormRadioGroup,
  FormCheckBox,
} from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_profile/update_profession`

class ProfileClients extends Component {
  state = {
    isLoading: false,
    user: {},
    result: null,
  }

  componentWillMount = () => {
    let { row } = this.props
    this.setState({ user: row })
  }

  handleChange = (name) => (event) =>
    this.changeUserState(name, event.target.value)

  changeUserState = (key, val) => {
    let user = Object.assign({}, this.state.user)
    user[key] = val
    this.setState({ user })
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { user } = this.state
    let { dispatch } = this.props
    instance.post(API, user).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
    })
  }

  renderTextField = (name) => {
    let { classes } = this.props
    let { user } = this.state
    return (
      <TextField
        key={name}
        className={classes.textField}
        value={user[name] != null ? user[name] : ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  renderRow = (text, comp) => {
    return (
      <React.Fragment key={text}>
        <Grid item xs={12} key={`${text}-left`}>
          <Typography color="secondary">{text}</Typography>
        </Grid>
        <Grid item xs={12} key={`${text}-right`}>
          {comp()}
        </Grid>
      </React.Fragment>
    )
  }

  renderCheckBox = (name, source) => {
    return (
      <FormCheckBox
        source={source}
        value={this.state.user[name]}
        onChange={(value) => this.changeUserState(name, value)}
      />
    )
  }

  renderRadioGroup = (name, source) => {
    return (
      <FormRadioGroup
        source={source}
        value={this.state.user[name]}
        onChange={this.handleChange(name)}
      />
    )
  }

  renderClients = () => {
    let { row, learn_by, professions, languages, doing_astrology_as } =
      this.props
    let { isLoading, result } = this.state
    let rows = {
      Profession: () =>
        this.renderCheckBox(
          "professions",
          Object.keys(professions).map((x) => ({
            text: professions[x],
            val: x,
          }))
        ),
      "Speaking languages": () =>
        this.renderCheckBox(
          "languages",
          Object.keys(languages).map((x) => ({ text: languages[x], val: x }))
        ),
      "Doing astrology as": () =>
        this.renderRadioGroup(
          "doing_astrology_as",
          Object.keys(doing_astrology_as).map((x) => ({
            text: doing_astrology_as[x],
            val: x,
          }))
        ),
      "Learn By": () =>
        this.renderRadioGroup(
          "learn_by",
          Object.keys(learn_by).map((x) => ({ text: learn_by[x], val: x }))
        ),
      "Guru name": () => this.renderTextField("guru_name"),
      Experience: () => this.renderTextField("experience"),
    }
    return (
      <div>
        <Grid container justify="center" alignItems="center" spacing={8}>
          {Object.keys(rows).map((x) => this.renderRow(x, rows[x]))}
        </Grid>

        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          label="Save"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
      </div>
    )
  }

  render() {
    return this.renderClients()
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let learn_by = enums.rows.learn_by.vt
  let professions = enums.rows.professions.vt
  let languages = enums.rows.languages.vt
  let doing_astrology_as = enums.rows.doing_astrology_as.vt
  return { learn_by, professions, languages, doing_astrology_as }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileClients))
