import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import Icon from "@material-ui/core/Icon"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import ProfileBasic from "./components/basic"
import ProfileClients from "./components/clients"
import ProfileBiography from "./components/biography"
import ProfileProfessional from "./components/professional"
import { instance } from "../../../utils"
import { Progress } from "../../../utils/components"
const API = `astrologer/astrologer_profile/get_data/`

const tabs = [
  {
    key: "basic",
    label: "Basic",
    icon: "account_circle",
    component: ProfileBasic,
  },
  {
    key: "professional",
    label: "Professional",
    icon: "school",
    component: ProfileProfessional,
  },
  {
    key: "clients",
    label: "Clients",
    icon: "supervised_user_circle",
    component: ProfileClients,
  },
  {
    key: "biography",
    label: "Biography",
    icon: "subject",
    component: ProfileBiography,
  },
]

class ProfileRoot extends Component {
  state = {
    tabVal: "basic",
    isLoading: true,
    row: null,
  }

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: `${API}${this.props.id}`,
    }).then(({ row }) => {
      this.setState({ row, isLoading: false })
    })
  }

  handleChange = (event, value) => {
    this.setState({ tabVal: value })
  }

  renderTabs = () => {
    return (
      <Tabs
        value={this.state.tabVal}
        onChange={this.handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        fullWidth
      >
        {tabs.map((item) => (
          <Tab
            value={item.key}
            key={item.key}
            icon={<Icon>{item.icon}</Icon>}
            label={item.label}
          />
        ))}
      </Tabs>
    )
  }

  render() {
    let { tabVal, isLoading, row } = this.state
    let tabItem = tabs.find((x) => x.key === tabVal)
    let Comp = tabItem && tabItem.component ? tabItem.component : null
    return (
      <div>
        {this.renderTabs()}
        <Card>
          <CardContent>
            {isLoading ? <Progress loading={true} /> : <Comp row={row} />}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, ownProps) => {
  let { user } = auth
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileRoot))
