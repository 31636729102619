import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"

const CHAT_SAVE_API = `chat/update`
class DisputeForm extends Component {
  state = {
    isLoading: false,
    result: null,
    chat_message: "",
  }

  handleSubmit = () => {
    let { chat_reference, receiver_id } = this.props
    let { chat_message } = this.state
    this.setState({ isLoading: true, result: null })
    instance({
      method: "post",
      url: CHAT_SAVE_API,
      data: { chat_reference, chat_message, receiver_id },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        this.props.onSuccess(result)
        this.setState({ chat_message: "" })
        this.props.dispatch(pushMsg(`message sent success !`, "success"))
      }
    })
  }

  renderForm = () => {
    let { classes } = this.props
    let { chat_message } = this.state
    return (
      <TextField
        label="Enter your message here"
        className={classes.textField}
        value={chat_message}
        onChange={(event) =>
          this.setState({ chat_message: event.target.value })
        }
        multiline
        rows="3"
      />
    )
  }

  render() {
    let { comment_reference } = this.props
    let { isLoading, result } = this.state
    return (
      <div style={{ marginTop: 5 }}>
        {this.renderForm()}
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          fullWidth
          color="secondary"
          label="Send"
          onClick={this.handleSubmit}
        />
      </div>
    )
  }
}

const styles = (theme) => ({
  container: {
    padding: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
})

const mapStateToProps = ({ requirement: requirementList }, ownProps) => {
  return {}
}

DisputeForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(withStyles(styles)(DisputeForm))
