import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"
import CustomStatusSwitch from "./statusSwitch"
import CustomForm from "./form"

import {
  ConfirmationDialog,
  ModalComponent,
} from "../../../../utils/components"
import { pushMsg } from "../../../../navigation/actions"
import { instance } from "../../../../utils"
const API = `astrologer/astrologer_custom_service/change_status/`
const API_DELETE = `astrologer/astrologer_custom_service/delete/`

class CustomRow extends Component {
  state = {
    confimrationOpen: false,
    isLoading: false,
    result: null,
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  handleDelete = () => {
    this.setState({ isLoading: true, result: null })
    let { dispatch, row, refreshData } = this.props
    instance.post(API_DELETE, row).then((result) => {
      this.setState({ isLoading: false })
      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      refreshData()
      dispatch(pushMsg(msg, "success"))
    })
  }

  renderDeleteButton = () => (
    <React.Fragment>
      <Button
        variant="text"
        style={{ float: "right", color: "red" }}
        onClick={() => this.setState({ confimrationOpen: true })}
      >
        <Icon>delete</Icon>
      </Button>
      <ConfirmationDialog
        _setState={this._setState}
        confimrationOpen={this.state.confimrationOpen}
        description="Are you sure to delete this service?"
        onSuccess={this.handleDelete}
      />
    </React.Fragment>
  )

  renderEditButton = () => (
    <React.Fragment>
      <Button
        variant="text"
        style={{ float: "right", color: "blue" }}
        onClick={() => this.setState({ modalOpen: true })}
      >
        <Icon>edit</Icon>
      </Button>
      <ModalComponent
        _setState={this._setState}
        modalOpen={this.state.modalOpen}
        title="Edit service"
        content={() => (
          <CustomForm
            _setState={this._setState}
            refreshData={this.props.refreshData}
            row={this.props.row}
          />
        )}
      />
    </React.Fragment>
  )

  render() {
    let { row } = this.props
    return (
      <div>
        <Typography color="primary" variant="subheading" gutterBottom>
          {row.acs_name} - {row.acs_service_rate} INR/booking
        </Typography>
        <Typography gutterBottom>{row.acs_description}</Typography>
        {this.renderDeleteButton()}
        {this.renderEditButton()}
        <CustomStatusSwitch row={row} />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(CustomRow))
