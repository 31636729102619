import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import ListItemText from "@material-ui/core/ListItemText"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import Icon from "@material-ui/core/Icon"
import Grid from "@material-ui/core/Grid"

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ModalComponent extends React.Component {
  state = {
    modalOpen: false,
  }

  handleClickOpen = () => {
    this.props._setState({ modalOpen: true })
  }

  handleClose = () => {
    this.props._setState({ modalOpen: false })
  }

  renderContent = () => null

  render() {
    const { classes, activator, content } = this.props
    return (
      <div>
        <Dialog
          fullScreen
          open={this.props.modalOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography
                variant="subtitle1"
                color="inherit"
                className={classes.flex}
              >
                {this.props.title || `update`}
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <div className={classes.content}>{content && content()}</div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    background: "#f8f8f8",
    overflowX: "hidden",
    padding: theme.spacing.unit,
    minHeight: "92vh",
  },
  flex: {
    flex: 1,
  },
  content: {
    maxWidth: 1170,
    margin: "0 auto",
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: 20,
      //background : '#fff',
    },
  },
})

ModalComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ModalComponent)
