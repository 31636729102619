import React, { Component } from "react"
import { Select, MenuItem, TextField, Grid } from "@material-ui/core"
import { countries } from "countries-list"

class PhoneInput extends Component {
  render() {
    const { onCountryChange, onPhoneChange, phone, country_code } = this.props

    return (
      <Grid container>
        <Grid item xs={4}>
          <Select
            value={country_code || 91}
            onChange={onCountryChange}
            fullWidth
          >
            <MenuItem value="0">-Select-</MenuItem>
            {Object.keys(countries).map((key) => (
              <MenuItem
                key={key}
                value={countries[key].phone}
              >{`${key} +${countries[key].phone}`}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            value={phone || ``}
            onChange={onPhoneChange}
            placeholder="Enter phone number"
            autoFocus
          />
        </Grid>
      </Grid>
    )
  }
}

export default PhoneInput
