import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import { instance } from "../../../utils"
import { Progress } from "../../../utils/components"
import BirthDetailsBlock from "../../birthdetails/components/block"
const API = `birthdetail/birth_detail/get_rows`

class BirthDetails extends Component {
  state = {
    isLoading: true,
    result: false,
    rows: [],
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  componentWillMount = () => this.fetchRow()

  fetchRow = () => {
    instance({
      method: "post",
      url: API,
      data: { filter: { user_id: 1 } },
    }).then((result) => {
      let { rows, res } = result
      this.setState({ isLoading: false, rows })
    })
  }

  render() {
    let { isLoading, rows } = this.state
    let { requirement_bds, onChange } = this.props
    if (isLoading) return <Progress />
    return (
      <React.Fragment>
        {rows.map((x) => (
          <Card key={x.birth_id} style={{ margin: 10 }}>
            <CardContent>
              <BirthDetailsBlock row={x} />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={requirement_bds.includes(x.birth_id)}
                    onChange={(event) => {
                      if (event.target.checked) requirement_bds.push(x.birth_id)
                      else
                        requirement_bds = requirement_bds.filter(
                          (y) => y !== x.birth_id
                        )
                      onChange(requirement_bds)
                    }}
                  />
                }
                label="Secondary"
              />
            </CardContent>
          </Card>
        ))}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(BirthDetails))
