const MY_IP = `192.168.1.7`
const config_local = {
  api_base: `http://${MY_IP}/monkvyasa/api/index.php/`,
  base: "/",
}

const config_prod = {
  api_base: "https://www.api.monkvyasa.com/",
  base: "https://www.beta.monkvyasa.com/",
}

let config = config_prod

let { base, api_base } = config
export { base, api_base }
export default config
