import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"

import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"

const API_SERVICE = `astrologer/astrologer_admin/change_status/`
const API_REGISTRATION = `astrologer/astrologer_registration/change_status/`

class AstrologerActionsDelete extends Component {
  state = {
    modalOpen: false,
    isLoading: false,
    result: null,
  }

  handleClick = () => {
    this.setState({ isLoading: true, result: null })
    let { dispatch, user_id, status, regStatus, onSuccess } = this.props
    const API = regStatus ? API_REGISTRATION : API_SERVICE
    instance.post(`${API}${status}`, { user_id }).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
      onSuccess && onSuccess()
    })
  }

  render() {
    let { label } = this.props
    let { result, isLoading } = this.state
    return (
      <React.Fragment>
        <IconButton
          color="secondary"
          variant="text"
          size="small"
          disabled={isLoading}
          onClick={this.handleClick}
        >
          <Icon>delete</Icon>
        </IconButton>
        <FormResult result={result} />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(AstrologerActionsDelete)
)
