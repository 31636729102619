import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"

import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { timeToHumanReadable } from "../../../../utils/functions"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_phone_availability/update_next_slot/`

class PhoneNextSlot extends Component {
  state = {
    isLoading: false,
    result: null,
    slotDate: false,
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { dispatch, phone_service } = this.props
    instance
      .post(API, {
        phone_service_slot_expiry: this.state.slotDate.valueOf(),
        user_id: phone_service.user_id,
      })
      .then((result) => {
        this.setState({ isLoading: false })
        let { res, msg } = result
        if (res != 1) {
          this.setState({ result })
          return
        }
        dispatch(pushMsg(msg, "success"))
        this.props._setState({ modalOpen: false })
      })
  }

  handleChange = (name) => (event) => {
    this.handleSubmit()
    this.setState({ [name]: event.target.checked ? 1 : 2 })
  }

  handleChangeSelect = (name) => (event) => {
    let currTime = new Date()
    let slotDate = this.state.slotDate || currTime
    let value = parseInt(event.target.value)
    if (currTime.getMonth() !== slotDate.getMonth() && name == "days") {
      slotDate.setMonth(currTime.getMonth())
    }
    switch (name) {
      case "days":
        slotDate.setDate(currTime.getDate() + value)
        break
      case "hours":
        slotDate.setHours(currTime.getHours() + value)
        break
      case "minutes":
        slotDate.setMinutes(currTime.getMinutes() + value)
        break
    }
    this.setState({ slotDate })
  }

  renderSelect = (name, label, values) => (
    <FormControl style={{ margin: 5 }}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        native
        //value={state.age}
        onChange={this.handleChangeSelect(name)}
        inputProps={{
          name,
          id: name,
        }}
      >
        {values.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
    </FormControl>
  )

  render() {
    let { result, isLoading, slotDate } = this.state
    let { phone_service } = this.props
    if (!phone_service) return null
    let checked =
      phone_service.phone_service_status == 1 &&
      phone_service.phone_service_slot_status == 1
    let label = checked ? (
      <Typography variant="h5" style={{ color: "green" }}>
        You are available to take phonecalls now
      </Typography>
    ) : (
      <Typography variant="h5" color="error">
        You are Not available for phonecalls now
      </Typography>
    )
    return (
      <div style={{ textAlign: "center" }}>
        {label}
        <Typography variant="subtitle2">
          Would you like to set next slot to go {checked ? "OFFLINE" : "ONLINE"}{" "}
          ?
        </Typography>
        {this.renderSelect("days", "Days", [0, 1, 2, 3, 4, 5])}
        {this.renderSelect(
          "hours",
          "Hours",
          Array.apply(null, { length: 24 }).map(Number.call, Number)
        )}
        {this.renderSelect(
          "minutes",
          "Minutes",
          Array.apply(null, { length: 60 }).map(Number.call, Number)
        )}
        {slotDate && (
          <Typography variant="caption" align="center" color="secondary">
            Your availability are set to go {checked ? "OFFLINE" : "ONLINE"} @{" "}
            {timeToHumanReadable(slotDate)}
          </Typography>
        )}
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          onClick={this.handleSubmit}
          label="Confirm"
          fullWidth
          style={{ margin: 20, maxWidth: 300 }}
          variant="contained"
          color="primary"
        />
        <Button
          fullWidth
          variant="outlined"
          style={{ margin: "0px 20px", maxWidth: 300 }}
          onClick={() => this.props._setState({ modalOpen: false })}
        >
          No Thanks
        </Button>
        <Typography variant="caption" align="center">
          You need to change the availability @time
        </Typography>
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth }, { phone_service }) => {
  return { phone_service }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneNextSlot))
