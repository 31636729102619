import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_profile/update_clients`

class ProfileClients extends Component {
  state = {
    isLoading: false,
    user: {},
    result: null,
  }

  componentWillMount = () => {
    let { row, clients } = this.props
    let user = {}
    Object.values(clients).map((x) => {
      user[x] = row[`clients_${x}`]
    })
    this.setState({ user })
  }

  handleChange = (name) => (event) =>
    this.changeUserState(name, event.target.value)

  changeUserState = (key, val) => {
    let user = Object.assign({}, this.state.user)
    user[key] = val
    this.setState({ user })
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { user } = this.state
    let { clients } = this.props
    let data = {}
    Object.values(clients).map((x) => {
      data[`clients_${x}`] = user[x]
    })
    data.user_id = this.props.row.user_id
    let { dispatch } = this.props
    instance.post(API, data).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
    })
  }

  renderTextField = (name, label) => {
    let { classes } = this.props
    let { user } = this.state
    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={user[name] != null ? user[name] : ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  renderClients = () => {
    let { clients, row } = this.props
    let { isLoading, result } = this.state
    let rows = []
    Object.values(clients).map((x) => {
      rows.push(this.renderTextField(x, `In ${x} `))
    })
    return (
      <div>
        {rows}
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          label="Save"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
      </div>
    )
  }

  render() {
    return this.renderClients()
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let clients = enums.rows.clients.vt
  return { clients }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileClients))
