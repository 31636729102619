import { forEach } from "lodash"
let initialState = {
  isLoading: true,
  rows: {},
  total: 0,
  ids: [],
}

const enumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ENUMS_PENDING":
      return {
        ...state,
        isLoading: true,
      }
    case "FETCH_ENUMS_FULFILLED":
    case "FETCH_ENUMS_REJECTED":
      let rowsArray = action.payload.enums
      let rows = {}
      let ids = []
      forEach(rowsArray, (value, key) => {
        rows[key] = value
        ids.push(key)
      })

      return {
        ...state,
        rows,
        ids,
        isLoading: false,
      }
    case "UPDATE_ENUMS_COLUMN":
      let column = action.payload.column
      let column_val = action.payload.column_val
      return {
        ...state,
        [column]: column_val,
      }
    default:
      return state
  }
}
export default enumsReducer
