import { forEach } from "lodash"
let initialState = {
  isLoading: true,
  ids: [],
  idsLoading: [],
  rows: {},
}

let id, row, ids, idsLoading

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_LOADING_IDS":
      return {
        ...state,
        idsLoading: [],
      }
    case "FETCH_USER_ROW":
      id = action.payload.id
      return {
        ...state,
        idsLoading: [...state.idsLoading, id],
      }
    case "PUSH_USER_ROW":
      row = action.payload.row
      id = action.payload.id
      ids = [...state.ids]
      ids = ids.includes(id) ? ids : [...ids, id]
      idsLoading = state.idsLoading.filter((x) => x !== id)
      return {
        ...state,
        rows: {
          ...state.rows,
          [id]: row,
        },
        ids,
        idsLoading,
      }
    default:
      return state
  }
}
export default usersReducer
