import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import PhonecallListRow from "./row"
import { instance } from "../../../utils"
import { PaginationComponent } from "../../../utils/components"
import { UserSearch } from "../../../utils/components/misc"
import ClearButton from "./clearButton"
const API = `phonecall/get_rows`

class PhonecallList extends Component {
  state = {
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 5,
    filter: {},
    showAllCalls: false,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { page, limit, showAllCalls, filter } = this.state
    let start = page * limit
    let { user } = this.props
    console.log(filter)
    if (!showAllCalls) filter.call_status = 5
    else delete filter.call_status
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter, order_by: "call_join_date-desc" },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  renderPagination = () => {
    return (
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    )
  }

  renderTop = () => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showAllCalls}
              onChange={(event) =>
                this.setState(
                  { showAllCalls: event.target.checked, page: 0 },
                  this.fetchRows
                )
              }
              value="checkedA"
            />
          }
          label="Show All calls"
        />
        <UserSearch
          onChange={(res) => {
            let filter = {}
            if (res && res[0]) filter = { user_id: res[0].id }
            this.setState({ filter, page: 0 }, this.fetchRows)
          }}
        />
      </FormGroup>
    )
  }

  render() {
    let { rows } = this.state
    return (
      <Fragment>
        {this.renderTop()}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography h3>{this.state.total} PHONECALLS </Typography>
          <ClearButton />
        </div>
        {Object.values(rows).map((row, idx) => {
          return (
            <PhonecallListRow
              key={row.phonecall_id}
              row={row}
              fetchRows={this.fetchRows}
            />
          )
        })}
        {this.renderPagination()}
      </Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, ownProps) => {
  let { user } = auth
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(PhonecallList))
