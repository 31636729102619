import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    //borderTop : "1px solid #ccc"
  },
})

function RateStructure(props) {
  const { classes, fee_structure, countn, min } = props
  return (
    <React.Fragment>
      <Typography variant="h5" style={{ marginTop: 20 }}>
        {" "}
        You have {countn} bookings here.{" "}
      </Typography>
      <List className={classes.root}>
        {fee_structure.map((x) => (
          <ListItem key={x.rate} selected={x.selected}>
            <Avatar>
              <Icon>{!x.selected ? "chevron_right" : "check"}</Icon>
            </Avatar>
            <ListItemText
              primary={x.text}
              secondary={`You can set an amount between ${min} - ${x.rate} INR`}
            />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
}

RateStructure.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RateStructure)
