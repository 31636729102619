import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import Icon from "@material-ui/core/Icon"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import PhoneService from "./phone"
import ReportService from "./report"
import CustomService from "./custom"

import { instance } from "../../../utils"
import { Progress } from "../../../utils/components"
const API = `astrologer/astrologer_profile/get_data/`

class ToBeImp extends Component {
  render() {
    return <div>Impliment ME</div>
  }
}

const tabs = [
  {
    key: "phone",
    label: "Phone",
    icon: "phone",
    component: PhoneService,
  },
  {
    key: "report",
    label: "Report",
    icon: "question_answer",
    component: ReportService,
  },
  {
    key: "custom",
    label: "Custom",
    icon: "photo_filter",
    component: CustomService,
  },
]

class ServicesRoot extends Component {
  state = {
    tabVal: "phone",
    row: null,
  }

  handleChange = (event, value) => {
    this.setState({ tabVal: value })
  }

  renderTabs = () => {
    return (
      <Tabs
        value={this.state.tabVal}
        onChange={this.handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        fullWidth
      >
        {tabs.map((item) => (
          <Tab
            value={item.key}
            key={item.key}
            icon={<Icon>{item.icon}</Icon>}
            label={item.label}
          />
        ))}
      </Tabs>
    )
  }

  render() {
    let { tabVal, isLoading, row } = this.state
    let tabItem = tabs.find((x) => x.key === tabVal)
    let Comp = tabItem && tabItem.component ? tabItem.component : null
    return (
      <div>
        {this.renderTabs()}
        <Card>
          <CardContent>
            <Comp row={this.props.user} />
          </CardContent>
        </Card>
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, { row }) => {
  let user = row
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(ServicesRoot))
