import React, { Component } from "react"
import { render } from "react-dom"
import Downshift from "downshift"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"

import UserListCard from "../../../modules/users/components/listCard"
import { instance } from "../../functions"
const API = `user/user_admin/get_rows`

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      margin="normal"
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

class AutoComplete extends Component {
  state = {
    inputValue: "",
    isLoading: false,
    rows: [],
    limit: 5,
    filter: [],
    selectedItem: [],
  }

  componentWillMount = () => {
    let { filterExtra } = this.props
    if (!filterExtra) return
    let filter = this.state.filter
    filter.push(filterExtra)
    this.setState({ filter })
  }

  fetchRows = () => {
    let { limit, filter } = this.state
    instance
      .post(API, { limit, filter: { ...filter } })
      .then(({ rows }) => this.setState({ isLoading: true, rows }))
  }

  applySearch = (name, value) => {
    let entry = { name, value, condn: "like" }
    let { filter } = this.state
    let index = filter.findIndex((x) => x.name == name)
    if (index) filter[index] = entry
    else filter.push(entry)

    this.setState({ filter, page: 0 }, this.fetchRows)
  }

  renderItem = (item) => <div>{item.first_name}</div>

  handleDelete = (item) => () => {
    this.setState((state) => {
      const selectedItem = [...state.selectedItem]
      selectedItem.splice(selectedItem.indexOf(item), 1)
      let { onChange } = this.props
      onChange && onChange(selectedItem)
      return { selectedItem }
    })
  }

  handleChange = (item) => {
    let { selectedItem } = this.state

    if (selectedItem.indexOf(item) === -1) {
      selectedItem = [...selectedItem, item]
    }

    this.setState({
      inputValue: "",
      selectedItem,
    })

    let { onChange } = this.props
    onChange && onChange(selectedItem)
  }

  handleInputChange = (event) => {
    let inputValue = event.target.value
    this.setState({ inputValue: inputValue })
    this.applySearch("first_name,last_name,phone", inputValue)
  }

  handleKeyDown = (event) => {
    const { inputValue, selectedItem } = this.state
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      })
    }
  }

  render() {
    let { placeholder, style, classes, ...rest } = this.props
    const { inputValue, selectedItem, rows } = this.state

    let suggestions = rows
    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
        itemToString={(item) => (item ? item.first_name : "")}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItem.map((item) => (
                  <Chip
                    key={item.id}
                    tabIndex={-1}
                    label={item.first_name}
                    className={classes.chip}
                    onDelete={this.handleDelete(item)}
                  />
                )),
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                placeholder: placeholder || "Enter search",
              }),
            })}
            <Paper {...getMenuProps()} className={classes.paper} square>
              {isOpen
                ? suggestions.map((item, index) => (
                    <div
                      {...getItemProps({
                        key: `${item.id}-${index}`,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        },
                      })}
                    >
                      <UserListCard row={item} />
                    </div>
                  ))
                : null}
            </Paper>
          </div>
        )}
      </Downshift>
    )
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
})
export default withStyles(styles)(AutoComplete)
