import { instance } from "../../utils"
import { history } from "../../store"
import { pushMsg } from "../../navigation/actions"
const FETCH_AUTH_API = `auth/is_logged_in`
const LOGOUT_API = `auth/logout`

export const fetchAuth =
  (data = {}) =>
  (dispatch) => {
    dispatch({
      type: "FETCH_AUTH",
      payload: instance({
        method: "post",
        url: FETCH_AUTH_API,
        data: data,
      }).then((result) => {
        if (result.res == 1 && result.grouped_in != 1) {
          dispatch(pushMsg("Please login as admin", "error"))
          dispatch(logOut())
          return
        }

        dispatch({
          type: "FETCH_AUTH_DONE",
          payload: result,
        })
      }),
    })
  }

export const logOut = () => (dispatch) => {
  dispatch({
    type: "LOGOUT",
    payload: instance({
      url: LOGOUT_API,
    }),
  }).then(() => {
    history.replace("/")
  })
}
