import { instance } from "../../utils"
const FETCH_ROWS_API = `astrologer/astrologer_public/list_data`

export const fetchRow = (id) => (dispatch) => {
  dispatch({
    type: "FETCH_USER_ROW",
    payload: { id },
  })
  dispatch({
    type: "FETCH_USER",
    payload: instance({
      method: "post",
      url: `user/profile_public/${id}/1`,
      data: { id },
    }).then(({ res, row }) => {
      if (res == 1)
        dispatch({
          type: "PUSH_USER_ROW",
          payload: { id, row },
        })
    }),
  })
}

export const clearLoadingIds = () => (dispatch) => {
  dispatch({
    type: "CLEAR_LOADING_IDS",
  })
}
