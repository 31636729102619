import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import green from "@material-ui/core/colors/green"
import amber from "@material-ui/core/colors/amber"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import { removeMsg } from "../../actions"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: "#191717",
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
})

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
})

class SnackbarWrapper extends React.Component {
  state = {
    open: true,
  }

  handleClick = () => {
    this.setState({ open: true })
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { classes, row } = this.props
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.open}
        autoHideDuration={3000}
        onClose={this.handleClose}
        onExited={() => this.props.dispatch(removeMsg(this.props.row.id))}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={row.type || "info"}
          message={row.msg}
        />
      </Snackbar>
    )
  }
}

SnackbarWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps1 = ({ msg }) => ({})

const SnackbarWrapperContent = connect()(withStyles(styles2)(SnackbarWrapper))

class SnackbarMsg extends React.Component {
  state = {}
  componentWillMount = () => {
    let { rows } = this.props
    this.setState({ rows })
  }

  render() {
    let { rows } = this.props
    return (
      <div>
        {Object.values(rows).map((item, index) => (
          <SnackbarWrapperContent key={item.id} row={item} />
        ))}
      </div>
    )
  }
}

const mapStateToProps = ({ msg }) => ({
  rows: msg.rows,
})

export default connect(mapStateToProps)(SnackbarMsg)
