import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"

class ReportRow extends Component {
  render() {
    let { title, text, classes, onChange } = this.props
    return (
      <React.Fragment>
        <Typography variant="subheading" color="secondary">
          {title}
        </Typography>
        <TextField
          defaultValue={text || ``}
          className={classes.input}
          onChange={onChange}
          fullWidth
          multiline
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  input: {
    margin: "20px 0",
  },
})

const mapStateToProps = ({ auth }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportRow))
