import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import PhoneInput from "../../../../utils/components/misc/phoneInput"
import { AutoComplete } from "../../../../utils/components"
import { FormResult, FormButton } from "../../../../utils/form"
import { instance } from "../../../../utils"
import { pushMsg } from "../../../../navigation/actions"

const API = `user/user_profile/update_basic`
const API_CITY_FETCH = `utils/cities_json`

class ProfileBasic extends Component {
  state = {
    user: this.props.user,
    cities: {},
    isLoading: false,
    result: null,
  }

  componentWillMount = () => this.fetchCity()

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { phone, country_code, first_name, last_name, email, city } =
      this.state.user
    let { dispatch, history } = this.props
    let data = {
      phone,
      country_code,
      first_name,
      last_name,
      email,
      city,
      user_id: this.props.row.user_id,
    }
    instance.post(API, data).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
    })
  }

  fetchCity = () => {
    instance({
      url: API_CITY_FETCH,
    }).then((cities) => {
      this.setState({ cities })
    })
  }

  handleChange = (name) => (event) =>
    this.changeUserState(name, event.target.value)

  changeUserState = (key, val) => {
    let user = Object.assign({}, this.state.user)
    user[key] = val
    this.setState({ user })
  }

  renderTextField = (name, label) => {
    let { classes } = this.props
    let { user } = this.state
    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={user[name] != null ? user[name] : ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  renderPhone = () => {
    let { phone, country_code } = this.state.user
    return (
      <PhoneInput
        className={this.props.classes.textField}
        onCountryChange={this.handleChange("country_code")}
        onPhoneChange={this.handleChange("phone")}
        phone={phone}
        country_code={country_code}
      />
    )
  }

  render() {
    let { result, isLoading, user } = this.state

    return (
      <div>
        {this.renderTextField("first_name", "First Name")}
        {this.renderTextField("last_name", "Last Name")}
        {this.renderTextField("email", "Email")}

        <AutoComplete
          suggestions={Object.values(this.state.cities).map((val, key) => ({
            label: `${val.name} ( ${val.state} )`,
            value: val.name,
          }))}
          style={{ margin: "20px 0" }}
          onChange={(selection) =>
            this.changeUserState("city", selection.value)
          }
          placeholder="Enter your city"
          initialInputValue={user.city}
        />
        {this.renderPhone()}
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          label="Save"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
      </div>
    )
  }
}

const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing.unit,
  },
})

const mapStateToProps = ({ auth, enums }, { row }) => {
  let user = row
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileBasic))
