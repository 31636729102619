import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { instance } from "../../../utils/functions"
import { Progress, ConfirmationDialog } from "../../../utils/components"
import { pushMsg } from "../../../navigation/actions"
const API = `phonecall/phonecall_admin/clear_pending_calls`

class PhonecallCancelButton extends Component {
  state = {
    confimrationOpen: false,
    isLoading: false,
    result: null,
  }

  _setState = (newState) => this.setState(newState)

  handleClick = () => {
    let { dispatch, onSuccess } = this.props
    this.setState({ isLoading: true, result: null })
    instance.post(API).then((result) => {
      this.setState({ isLoading: false, result })
      dispatch(pushMsg(result.msg))
      onSuccess && onSuccess()
    })
  }

  renderResult = () => {
    let { result } = this.state
    if (!result) return null
    let { total, calls } = result
    return (
      <React.Fragment>
        <Typography>CALLS PENDING : {total}</Typography>
        {calls.map((x) => (
          <Typography>{x}</Typography>
        ))}
      </React.Fragment>
    )
  }

  render() {
    if (this.state.isLoading) return <Progress />
    return (
      <Fragment>
        {this.renderResult()}
        <Button
          variant="outlined"
          style={{ color: "blue" }}
          onClick={this.handleClick}
        >
          CLEAR PENDING CALLS
        </Button>
      </Fragment>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ enums }, ownProps) => {
  let disputeStatusMsg = enums.rows.dispute_status.vt
  return { disputeStatusMsg }
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallCancelButton)
)
