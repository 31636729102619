import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"

class SolutionLifetimeAgeForm extends Component {
  state = {
    lifetime_id: null,
    lifetime_agefrom: 0,
    lifetime_ageto: 0,
  }

  render() {
    let { lifetime_id, lifetime_ageto, lifetime_agefrom } = this.state
    let { pushRow } = this.props
    return (
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <TextField
            placeholder="Age from"
            onChange={(e) =>
              this.setState({ lifetime_agefrom: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            placeholder="Age to"
            onChange={(e) => this.setState({ lifetime_ageto: e.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() =>
              pushRow(lifetime_agefrom, lifetime_ageto, lifetime_id)
            }
          >
            Add
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme) => ({})

export default withStyles(styles)(SolutionLifetimeAgeForm)
