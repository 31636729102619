import Authentication from "../modules/auth"
import LogoutPage from "../modules/auth/logoutPage"
import AstrologerList from "../modules/astrologer/list"
import UsersList from "../modules/users/list"
import ReportBookingList from "../modules/report/list"
import PhoneCallList from "../modules/phonecall/list"
import LiveChat from "../modules/live-chat"
import Analytics from "../modules/analytics"

const routes = [
  {
    path: "/",
    exact: true,
    component: AstrologerList,
    isProtected: true,
  },
  {
    path: "/astrologers",
    exact: true,
    component: AstrologerList,
    isProtected: true,
  },
  {
    path: "/users",
    exact: true,
    component: UsersList,
    isProtected: true,
  },
  {
    path: "/report-booking-list",
    component: ReportBookingList,
    isProtected: true,
  },
  {
    path: "/phonecall-list",
    component: PhoneCallList,
    isProtected: true,
  },
  {
    path: "/authentication",
    component: Authentication,
  },
  {
    path: "/logout",
    component: LogoutPage,
  },
  {
    path: "/live-chat",
    component: LiveChat,
    exact: true,
    isProtected: true,
  },
  {
    path: "/analytics",
    component: Analytics,
    exact: true,
    isProtected: true,
  },
]

export default routes
