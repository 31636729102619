import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import { instance } from "../../utils"
import { Progress } from "../../utils/components"
import { EmptyCard } from "../../utils/components/misc"
import BirthDetailsBlock from "./components/block"
const API = `birthdetail/birth_detail/get_bds`

const styles = (theme) => ({})

class BirthDetails extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    isLoading: false,
    rows: [],
  }

  componentDidMount = () => this.fetchRows()

  fetchRows = () => {
    let { birth_ids } = this.props
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { birth_ids },
    }).then((result) => {
      let { rows } = result
      this.setState({ rows, isLoading: false })
    })
  }

  renderRows = () => {
    let { rows } = this.state
    if (!rows.length) return <EmptyCard msg="Birth details missing!" />
    return (
      <div>
        {rows.map((x) => (
          <div style={{ marginTop: 20, marginBottom: 10 }} key={x}>
            <BirthDetailsBlock row={x} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    if (this.state.isLoading) return <Progress />
    return <div>{this.renderRows()}</div>
  }
}

const mapStateToProps = (
  { enums, birthDetails, requirement: requirementList, auth },
  { requirement }
) => {
  let birth_ids = requirement ? requirement.requirement_bds : []
  return { birth_ids }
}

export default connect(mapStateToProps)(withStyles(styles)(BirthDetails))
