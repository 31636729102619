import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"

import { guid, convertToBDTime } from "../../../utils/functions"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const formatDate = function (date) {
  return date
  date = new Date(convertToBDTime(new Date(date * 1000)))
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  let str = [
    "<span style=''>",
    monthNames[date.getMonth()],
    date.getDate(),
    date.getFullYear(),
    "</span>",
    " - <span style='color:#000'>",
    strTime,
    "</span>",
  ].join(" ")
  return str
}

class BirthDetailsBlock extends Component {
  render() {
    let { row, classes } = this.props
    return (
      <div>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: 40 }}>
              {row.birth_gender == 1 ? "portrait" : "face"}
            </Icon>
            <Typography>{row.birth_gender == 1 ? "Male" : "Female"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.name}>{row.birth_name}</Typography>
            <Typography
              className={classes.city}
              color="textSecondary"
              gutterBottom
            >
              <Icon style={{ fontSize: 14, marginRight: 5 }}>location_on</Icon>
              {row.birth_pob}
            </Typography>
            <Typography
              className={classes.city}
              color="textSecondary"
              gutterBottom
            >
              <Icon style={{ fontSize: 14, marginRight: 5 }}>date_range</Icon>
              <span
                dangerouslySetInnerHTML={{ __html: formatDate(row.birth_dob) }}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme) => ({
  name: {
    color: "#408b90",
    fontSize: 18,
  },
  city: {
    fontSize: 14,
    color: "#4c402b",
  },
})

const mapStateToProps = ({ birthDetails, enums, requirement, auth }, {}) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(BirthDetailsBlock))
