import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import ServiceSlider from "../components/slider"
import RateStructure from "../components/rateStructure"
import { get_service_rate_range } from "../functions"
import { instance } from "../../../../utils"
import { FormResult, FormButton } from "../../../../utils/form"
import { pushMsg } from "../../../../navigation/actions"

const API = `astrologer/astrologer_report_service/update/`

class ReportRate extends Component {
  state = {
    onEdit: this.props.onEdit || this.props.row.mr_service_rate <= 0,
    isLoading: false,
    mr_service_rate: this.props.row.mr_service_rate || 0,
    result: null,
    rangeLoading: true,
    min_rate: 0,
    max_rate: 0,
    fee_structure: [],
    countn: 0,
  }

  componentDidMount = () => {
    get_service_rate_range(
      2,
      this.props.row.mr_service_id,
      this.props.user_id,
      (result) => {
        this.setState({ rangeLoading: false })
        let { res, min_rate, max_rate, fee_structure, countn } = result
        this.setState({ min_rate, max_rate, fee_structure, countn })
      }
    )
  }

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let { mr_service_rate } = this.state
    let { dispatch, row } = this.props
    row.mr_service_rate = mr_service_rate
    instance.post(API, row).then((result) => {
      this.setState({ isLoading: false })
      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      this.setState({ onEdit: false })
      dispatch(pushMsg(msg, "success"))
    })
  }

  renderForm = () => {
    let {
      isLoading,
      result,
      mr_service_rate,
      min_rate,
      max_rate,
      fee_structure,
      countn,
    } = this.state
    return (
      <div>
        <ServiceSlider
          min={min_rate}
          max={max_rate}
          defaultValue={parseInt(mr_service_rate)}
          onChange={(mr_service_rate) => {
            this.setState({ mr_service_rate })
          }}
        />
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          fullWidth
          variant="outlined"
          color="primary"
          label={this.props.row.mr_service_rate > 0 ? "Save" : "Add rate"}
          onClick={this.handleSubmit}
          style={{ maxWidth: 600, margin: 0 }}
        />
        <RateStructure
          fee_structure={fee_structure}
          countn={countn}
          min={min_rate}
          max={max_rate}
        />
      </div>
    )
  }

  render = () => {
    let { onEdit, mr_service_rate } = this.state
    if (onEdit) return this.renderForm()
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="subtitle1">
            {" "}
            {mr_service_rate} INR/Booking{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            size="small"
            onClick={() => this.setState({ onEdit: true })}
          >
            {" "}
            <Icon>edit</Icon> Edit rate{" "}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ auth, enums }, { row }) => {
  let user_id = row.user_id
  return { user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(ReportRate))
